import { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, deepCopy } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import Select from 'react-select';
import Lottie from "lottie-react";
import { confirmAlert } from 'react-confirm-alert';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import InputField from '../../../components/formulier/inputfield';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import creatingOfferteAnimation from "../../../assets/animations/create-dossier.json";

//styles
import './offerte.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-confirm-alert/src/react-confirm-alert.css';


const OfferteBewerken = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [params, setParams] = useSearchParams();
    const offerteId = params.get("id");
    const environment = process.env.REACT_APP_APP_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOfferte, setIsLoadingOfferte] = useState(true);
    const [offerteLogs, setOfferteLogs] = useState([]);
    const [data, setData] = useState([]);
    const [offerteData, setOfferteData] = useState([]);
    const [klanten, setKlanten] = useState([]);
    const [vestigingen, setVestigingen] = useState([]);
    const [medewerkers, setMedewerkers] = useState([]);
    const [contactpersonen, setContactpersonen] = useState([]);
    const [arrayContactpersonen, setArrayContactpersonen] = useState([]);
    const [optionsContactpersonen, setOptionsContactpersonen] = useState([]);
    const [optionContactpersoon, setOptionContactpersoon] = useState('');
    const [arrayContactpersonenFacturatie, setArrayContactpersonenFacturatie] = useState([]);
    const [optionsContactpersonenFacturatie, setOptionsContactpersonenFacturatie] = useState([]);
    const [optionContactpersoonFacturatie, setOptionContactpersoonFacturatie] = useState('');
    const [dropdownContactpersonen, setDropdownContactpersonen] = useState(false);
    const [soortenTransport, setSoortenTransport] = useState([]);
    const [optionsFtlLtl, setOptionsFtlLtl] = useState([
        {value: 'ftl', label: 'FTL'},
        {value: 'ltl', label: 'LTL'},
        {value: 'ftl_ltl', label: 'FTL en LTL'},
        {value: 'ltl_ltl', label: '2x LTL'}
    ]);
    const [maatstaven, setMaatstaven] = useState([]);
    const [infoMaatstaven, setInfoMaatstaven] = useState({});
    const [editableRows, setEditableRows] = useState(false);
    const [inhouden, setInhouden] = useState([]);
    const [kolomOptions, setKolomOptions] = useState([]);
    const [landen, setLanden] = useState([]);
    const [tijdsToeslagen, setTijdsToeslagen] = useState([]);
    const [douaneToeslagen, setDouaneToeslagen] = useState([]);
    const [afstapelToeslagen, setAfstapelToeslagen] = useState([]);
    const [dieselToeslagenOptions, setDieselToeslagenOptions] = useState([]);
    const [offerteSoorten, setOfferteSoorten] = useState([]);
    const [bijlagenOptions, setBijlagenOptions] = useState([]);
    const [voorwaarden, setVoorwaarden] = useState([]);
    const [showMapImage, setShowMapImage] = useState(false);
    const [mapImageUrl, setMapImageUrl] = useState('');
    const [initialZones, setInitialZones] = useState([]);
    const [initialLaadmeterGewicht, setInitialLaadmeterGewicht] = useState(null);
    const [savingOfferte, setSavingOfferte] = useState(false);
    const lottieAnimation = useRef();

    //Stap 1a
    const [klantGegevens, setKlantGegevens] = useState({
        label: 'Stap 1A - Klantgegevens',
        id: 'klantgegevens',
        icon: <Icon.PersonVcard />,
        valid: false,
        fields: {
            klant: {
                label: 'Klant',
                value: '',
                required: true,
                type: 'select'
            },
            accountmanager: {
                label: 'Accountmanager',
                value: '',
                required: true
            },
            omzetschatting: {
                label: 'Omzetschatting',
                value: '',
                required: true,
                type: 'number'
            },
            vestiging: {
                label: 'Vestiging',
                value: '',
                required: false,
                type: 'select'
            },
            bedrijfsnaam: {
                label: 'Bedrijfsnaam',
                value: '',
                required: true,
                type: 'text'
            },
            kvk_nummer: {
                label: 'KvK nummer',
                value: '',
                required: false,
                type: 'text'
            },
            btw_nummer: {
                label: 'BTW nummer',
                value: '',
                required: false,
                type: 'text'
            },
            postcode: {
                label: 'Postcode',
                value: '',
                required: true,
                type: 'text'
            },
            straatnaam: {
                label: 'Straat + huisnummer',
                value: '',
                required: true,
                type: 'text'
            },
            plaatsnaam: {
                label: 'Plaatsnaam',
                value: '',
                required: true,
                type: 'text'
            },
            accountnummer: {
                label: 'Accountnummer',
                value: '',
                required: false,
                type: 'text'
            },
            voornaam: {
                label: 'Voornaam',
                value: '',
                required: false,
                type: 'text'
            },
            achternaam: {
                label: 'Achternaam',
                value: '',
                required: false,
                type: 'text'
            },
            telefoonnummer: {
                label: 'Telefoonnummer',
                value: '',
                required: false,
                type: 'text'
            },
            emailadres: {
                label: 'E-mailadres',
                value: '',
                required: true,
                type: 'email'
            },
            mobiel: {
                label: 'Mobiel',
                value: '',
                required: false,
                type: 'text'
            },
            functie: {
                label: 'Functie',
                value: '',
                required: false,
                type: 'text'
            }
        }
    });

    //Stap 1b
    const [transportGegevens, setTransportGegevens] = useState({
        label: 'Stap 1B - Transportgegevens',
        id: 'transportgegevens',
        icon: <Icon.Truck />,
        valid: false,
        fields: {
            soort_transport: {
                label: 'Soort transport',
                value: '',
                required: true
            },
            ftl_ltl: {
                label: 'FTL of LTL',
                value: '',
                required: true
            }
        }
    });

    //Stap 1c
    const [offerteGegevens, setOfferteGegevens] = useState({
        label: 'Stap 1C - Offerte gegevens',
        id: 'offerte-gegevens',
        icon: <Icon.Receipt />,
        valid: false,
        fields: {
            opgesteld_voor: {
                label: 'Opgesteld voor',
                value: '',
                required: true
            },
            soort_offerte: {
                label: 'Soort offerte',
                value: '',
                required: true
            },
            // opgesteld_door: {
            //     label: 'Offerte opgesteld door',
            //     value: '',
            //     required: true,
            //     type: 'text'
            // },
            datum: {
                label: 'Datum',
                value: moment().format('YYYY-MM-DD'),
                required: true,
                type: 'date'
            },
            geldig_van: {
                label: 'Offerte geldig van',
                value: '',
                required: true,
                type: 'date'
            },
            geldig_tot: {
                label: 'Geldig tot',
                value: '',
                required: true,
                type: 'date'
            },
            betaaltermijn: {
                label: 'Betaaltermijn in dagen',
                value: '',
                required: true,
                type: 'number'
            }
        }
    });

    //Stap 1d
    const [facturatie, setFacturatie] = useState({
        label: 'Stap 1D - Facturatie',
        id: 'facturatie',
        icon: <Icon.PersonVcard />,
        valid: true,
        fields: {
            voornaam: {
                label: 'Voornaam',
                value: '',
                required: false,
                type: 'text'
            },
            achternaam: {
                label: 'Achternaam',
                value: '',
                required: false,
                type: 'text'
            },
            telefoonnummer: {
                label: 'Telefoonnummer',
                value: '',
                required: false,
                type: 'text'
            },
            emailadres: {
                label: 'E-mailadres',
                value: '',
                required: false,
                type: 'email'
            },
            mobiel: {
                label: 'Mobiel',
                value: '',
                required: false,
                type: 'text'
            },
            functie: {
                label: 'Functie',
                value: '',
                required: false,
                type: 'text'
            }
        }
    });

    //Stap 2a
    const [begeleidendSchrijven, setBegeleidendSchrijven] = useState({
        label: 'Stap 2a - Begeleidend schrijven',
        id: 'begeleidend-schrijven',
        icon: <Icon.Pen />,
        valid: false,
        fields: {
            aanhef: {
                label: 'Aanhef',
                value: '',
                required: true,
                type: 'text'
            },
            begeleidend_schrijven: {
                label: 'Begeleidend schrijven',
                value: '',
                required: true,
                type: 'text'
            }
        }
    });

    //Stap 2b
    const [lading, setLading] = useState({
        label: 'Stap 2b - Lading maatstaaf',
        id: 'lading',
        icon: <Icon.BoxSeamFill />,
        valid: false,
        fields: {
            ladingtype: {
                label: 'Ladingtype',
                value: '',
                required: true,
            },
            minimale_inhoud: {
                label: 'Min. inhoud',
                value: '',
                required: true,
                type: 'number'
            },
            maximale_inhoud: {
                label: 'Max. inhoud',
                value: '',
                required: true,
                type: 'number'
            },
            stappen: {
                label: 'Stappen',
                value: '',
                required: true,
                type: 'number'
            },
            ladingtype_twee: {
                label: 'Ladingtype',
                value: '',
                required: false,
            },
            minimale_inhoud_twee: {
                label: 'Min. inhoud',
                value: '',
                required: false,
                type: 'number'
            },
            maximale_inhoud_twee: {
                label: 'Max. inhoud',
                value: '',
                required: false,
                type: 'number'
            },
            stappen_twee: {
                label: 'Stappen',
                value: '',
                required: false,
                type: 'number'
            },
            inhouden: {
                label: 'Inhouden',
                value: [],
                required: false,
                type: 'checkbox'
            },
            kolommen_tonen: {
                label: 'Kolommen tonen',
                value: [],
                required: false,
            },
            toon_gewicht: {
                label: 'Kolom met gewicht in prijslijst tonen?',
                value: '',
                required: true,
                type: 'radio'
            }
        }
    });

    //Stap 2c
    const [land, setLand] = useState({
        label: 'Stap 2c - Land',
        id: 'land',
        icon: <Icon.GeoAltFill />,
        valid: false,
        fields: {
            bestemming: {
                label: 'Land van bestemming',
                value: '',
                required: false,
                type: 'select'
            },
            laadmeter_gewicht: {
                label: 'Laadmeter gewicht',
                value: '',
                required: true,
                type: 'number'
            }
        }
    });

    //Stap 3a
    const [toeslagen, setToeslagen] = useState({
        label: 'Stap 3a - Toeslagen',
        id: 'toeslagen',
        icon: <Icon.CashStack />,
        valid: true,
        fields: {
            tijdstoeslagen: {
                label: 'Tijdstoeslagen',
                value: [{
                    soort_toeslag: '',
                    eenheid: 'EURO',
                    per_eenheid: '',
                    opmerking: ''
                }],
                required: false,
                type: 'repeater'
            },
            douanetoeslagen: {
                label: 'Douanetoeslagen',
                value: [{
                    soort_toeslag: '',
                    eenheid: 'EURO',
                    per_eenheid: '',
                    opmerking: ''
                }],
                required: false,
                type: 'repeater'
            },
            afstapeltoeslagen: {
                label: 'Afstapeltoeslagen',
                value: [{
                    soort_toeslag: '',
                    eenheid: 'EURO',
                    per_eenheid: '',
                    opmerking: ''
                }],
                required: false,
                type: 'repeater'
            },
            overige_afspraken: {
                label: 'Overige afspraken',
                value: '',
                required: false,
                type: 'text'
            }
        }
    });

    //Stap 4a
    const [dieseltoeslagen, setDieseltoeslagen] = useState({
        label: 'Stap 4a - Dieseltoeslag',
        id: 'dieseltoeslag',
        icon: <Icon.FuelPumpDieselFill />,
        valid: false,
        fields: {
            dieseltoeslag: {
                label: 'Dieseltoeslag',
                value: '',
                required: true,
                type: 'radio'
            },
            soort_dieseltoeslag: {
                label: 'Soort dieseltoeslag',
                value: '',
                required: true,
                type: 'select'
            },
            voorwaarden: {
                label: 'Selecteer de geldende voorwaarden',
                value: [],
                required: false,
                type: 'checkbox'
            }
        }
    });

    //Stap 5a
    const [bijlagen, setBijlagen] = useState({
        label: 'Stap 5a - Bijlagen',
        id: 'bijlagen',
        icon: <Icon.Paperclip />,
        valid: true,
        fields: {
            bijlagen: {
                label: 'Bijlagen',
                value: [],
                required: false,
                type: 'checkbox'
            }
        }
    })

    //Stap 6a
    const [zones, setZones] = useState({
        label: 'Stap 6a - Zones',
        id: 'zones',
        icon: <Icon.GeoFill />,
        valid: false,
        fields: {
            zones: {
                label: 'Zones',
                value: [
                    {
                        naam: 'Zone 1',
                        postcodes: [{postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}],
                        prijzen: []
                    },
                    {
                        naam: 'Zone 2',
                        postcodes: [{postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}],
                        prijzen: []
                    },
                    {
                        naam: 'Zone 3',
                        postcodes: [{postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}],
                        prijzen: []
                    },
                    {
                        naam: 'Zone 4',
                        postcodes: [{postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}],
                        prijzen: []
                    },
                    {
                        naam: 'Zone 5',
                        postcodes: [{postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}, {postcode: ''}],
                        prijzen: []
                    }
                ],
                required: false,
                type: 'repeater'
            }
        }
    });

    //Stap 7a
    const [divisieVariabelen, setDivisieVariabelen] = useState({
        label: 'Stap 7a - Divisie variabelen',
        id: 'divisie-variabelen',
        icon: <Icon.Pen />,
        valid: true,
        fields: {
            bedrag_wachtkosten: {
                label: 'Bedrag wachtkosten',
                value: '',
                required: false,
                type: 'text'
            },
            percentage_waddentoeslag: {
                label: 'Percentage waddentoeslag',
                value: '',
                required: false,
                type: 'text'
            },
            percentage_doorleveringen: {
                label: 'Percentage doorleveringen',
                value: '',
                required: false,
                type: 'text'
            },
            percentage_tweede_levering: {
                label: 'Percentage tweede levering',
                value: '',
                required: false,
                type: 'text'
            },
            percentage_retourneren: {
                label: 'Percentage retourneren',
                value: '',
                required: false,
                type: 'text'
            },
            tijdstip_opgave_orders: {
                label: 'Tijdstip opgave orders',
                value: '',
                required: false,
                type: 'text'
            },
            tijdstip_zendingen_beschikbaarheid: {
                label: 'Tijdstip zendingen beschikbaarheid',
                value: '',
                required: false,
                type: 'text'
            },
            percentage_europallets: {
                label: 'Percentage europallets',
                value: '',
                required: false,
                type: 'text'
            },
            aantal_dagen_reclames: {
                label: 'Aantal dagen reclames',
                value: '',
                required: false,
                type: 'text'
            },
        }
    });

    const isFormValid = (obj) => {
        const requiredFields = Object.keys(obj).filter(key => obj[key].required);
        return requiredFields.every(key => obj[key].value !== '');
    }

    //Function to handle form changes
    const handleChange = (section, setSection, field, value) => {
        if (!value) {
            value = '';
        }
        const current = {...section};
        current.fields[field].value = value;
        if (field === 'dieseltoeslag' && value === 'false') {
            current.fields.soort_dieseltoeslag.required = false;
        }
        if (field === 'dieseltoeslag' && value === 'true') {
            current.fields.soort_dieseltoeslag.required = true;
        }
        if (field === 'soort_transport') {
            const begeleidend_schrijven = {...begeleidendSchrijven};
            begeleidend_schrijven.fields.begeleidend_schrijven.value = value.voorwoord;
            setBegeleidendSchrijven(begeleidend_schrijven);
        }
        if (field === 'bestemming') {
            setMapImageUrl(value.image_url);
        }
        if (field === 'kolommen_tonen') {
            if (current.fields[field].value.length > 2) {
                current.fields[field].value.pop();
                notifyError('Je kunt maximaal twee kolommen selecteren');
            }
        }
        if (field === 'ftl_ltl') {
            const currentLading = {...lading};
            if (value.value === 'ftl') {
                handleChange(lading, setLading, 'ladingtype', lading.fields.ladingtype.value ? lading.fields.ladingtype.value : maatstaven[0]);
                handleChange(lading, setLading, 'minimale_inhoud', lading.fields.minimale_inhoud.value ? lading.fields.minimale_inhoud.value : '1');
                handleChange(lading, setLading, 'maximale_inhoud', lading.fields.maximale_inhoud.value ? lading.fields.maximale_inhoud.value : '1');
                handleChange(lading, setLading, 'stappen', lading.fields.stappen.value ? lading.fields.stappen.value : '1');
                handleChange(lading, setLading, 'toon_gewicht', lading.fields.toon_gewicht.value ? lading.fields.toon_gewicht.value : 'false');
                currentLading.fields.ladingtype_twee.required = false;
                currentLading.fields.minimale_inhoud_twee.required = false;
                currentLading.fields.maximale_inhoud_twee.required = false;
                currentLading.fields.stappen_twee.required = false;
            } else if (value.value === 'ltl_ltl') {
                currentLading.fields.ladingtype_twee.required = true;
                currentLading.fields.minimale_inhoud_twee.required = true;
                currentLading.fields.maximale_inhoud_twee.required = true;
                currentLading.fields.stappen_twee.required = true;
            } else {
                handleChange(lading, setLading, 'ladingtype', lading.fields.ladingtype.value ? lading.fields.ladingtype.value : '');
                handleChange(lading, setLading, 'minimale_inhoud', lading.fields.minimale_inhoud.value ? lading.fields.minimale_inhoud.value : '');
                handleChange(lading, setLading, 'maximale_inhoud', lading.fields.maximale_inhoud.value ? lading.fields.maximale_inhoud.value : '');
                handleChange(lading, setLading, 'stappen', lading.fields.stappen.value ? lading.fields.stappen.value : '');
                handleChange(lading, setLading, 'toon_gewicht', lading.fields.toon_gewicht.value ? lading.fields.toon_gewicht.value : '');
                currentLading.fields.ladingtype_twee.required = false;
                currentLading.fields.minimale_inhoud_twee.required = false;
                currentLading.fields.maximale_inhoud_twee.required = false;
                currentLading.fields.stappen_twee.required = false;
            }
            setLading(currentLading);
            currentLading.valid = isFormValid(currentLading.fields);
        }
        current.valid = isFormValid(current.fields);
        setSection(current);
    }

    const handleInhoudenChange = (e) => {
        const current = {...lading};
        const currentKolommen = [...kolomOptions];
        const index = current.fields.inhouden.value.indexOf(Number(e.target.value));
        const inhoudenIndex = inhouden.findIndex((item) => item.value === Number(e.target.value));
        if (index !== -1) {
            current.fields.inhouden.value.splice(index, 1);
            currentKolommen.splice(index, 1);
            const kolommenIndex = current.fields.kolommen_tonen.value.findIndex((item) => item.value === Number(e.target.value));
            if (kolommenIndex !== -1) {
                current.fields.kolommen_tonen.value.splice(kolommenIndex, 1);
            }
        } else {
            current.fields.inhouden.value.push(Number(e.target.value))
            currentKolommen.push(inhouden[inhoudenIndex]);
        }
        setKolomOptions(currentKolommen);
        setLading(current);
    }

    const handleBijlagenChange = (e) => {
        const current = {...bijlagen};
        const index = current.fields.bijlagen.value.indexOf(Number(e.target.value));
        if (index !== -1) {
            current.fields.bijlagen.value.splice(index, 1);
        } else {
            current.fields.bijlagen.value.push(Number(e.target.value))
        }
        setBijlagen(current);
    }

    const handleToeslagenChange = (section, field, value, index) => {
        const current = {...toeslagen};
        current.fields[section].value[index][field] = value;
        current.valid = isFormValid(current);
        setToeslagen(current);
    }
    const handleToeslagen = (type, section) => {
        const current = {...toeslagen};
        if (type === 'add') {
            const obj = {
                soort_toeslag: '',
                    eenheid: 'EURO',
                    per_eenheid: '',
                    opmerking: ''
            };
            current.fields[section].value.push(obj);
        } else {
            current.fields[section].value.pop();
        }
        setToeslagen(current);
    }

    //Empty klantdata if clicked on x in bedrijfsnaam
    const emptyKlantData = (items) => {
        items.map((item) =>  {
            handleChange(klantGegevens, setKlantGegevens, item, '')
        })
    }
    const emptyFacturatieData = (items) => {
        items.map((item) =>  {
            handleChange(facturatie, setFacturatie, item, '')
        })
    }

    //Set klantdata on change select option bedrijfsnaam
    const setKlantData = (e) => {
        if (!e) {
            emptyKlantData([
                'klant',
                'vestiging',
                'kvk_nummer', 
                'btw_nummer', 
                'postcode', 
                'straatnaam', 
                'plaatsnaam', 
                'accountnummer', 
                'voornaam', 
                'achternaam', 
                'telefoonnummer', 
                'emailadres',
                'mobiel',
                'functie'
            ]);
            return;
        }
        var index = data.findIndex(item => item.id === e.value);
        const current = {...klantGegevens};
        const currentFacturatie = {...facturatie};
        const result = data[index];
        current.fields.klant.value = e.value ? e.value : '';

        const arrOptions = [];
        if (result.vestigingen.length > 0) {
            result.vestigingen.map((item, index) =>  {
                const obj = {
                    value: item.id,
                    label: item.naam
                };
                arrOptions.push(obj); 
            })
        }
        setVestigingen(arrOptions);

        current.fields.kvk_nummer.value = result.kvk_nummer ? result.kvk_nummer : '';
        current.fields.btw_nummer.value = result.btw_nummer ? result.btw_nummer : '';
        current.fields.postcode.value = result.postcode ? result.postcode : '';
        current.fields.straatnaam.value = result.straatnaam ? result.straatnaam : '';
        current.fields.plaatsnaam.value = result.plaatsnaam ? result.plaatsnaam : '';
        current.fields.accountnummer.value = result.accountnummer ? result.accountnummer : '';
        if (result.contactpersonen && result.contactpersonen.length === 1) {
            setOptionsContactpersonen([]);
            setOptionContactpersoon('');
            current.fields.voornaam.value = result.contactpersonen[0].voornaam ? result.contactpersonen[0].voornaam : '';
            current.fields.achternaam.value = result.contactpersonen[0].achternaam ? result.contactpersonen[0].achternaam : '';
            current.fields.telefoonnummer.value = result.contactpersonen[0].telefoonnummer ? result.contactpersonen[0].telefoonnummer : '';
            current.fields.emailadres.value = result.contactpersonen[0].email ? result.contactpersonen[0].email : '';
            current.fields.mobiel.value = result.contactpersonen[0].mobiel ? result.contactpersonen[0].mobiel : '';
            current.fields.functie.value = result.contactpersonen[0].functie ? result.contactpersonen[0].functie : '';
        } else {
            current.fields.voornaam.value = '';
            current.fields.achternaam.value = '';
            current.fields.telefoonnummer.value = '';
            current.fields.emailadres.value = '';
            current.fields.mobiel.value = '';
            current.fields.functie.value = '';

            const arrContactpersonen = [];
            result.contactpersonen.map((item, index) =>  {
                const obj = {
                    value: item.id,
                    label: item.voornaam + ' ' + item.achternaam
                }
                arrContactpersonen.push(obj);
            });
            setArrayContactpersonen(result.contactpersonen);
            setOptionsContactpersonen(arrContactpersonen);
        }
        if (result.contactpersonen_facturatie && result.contactpersonen_facturatie.length === 1) {
            setOptionsContactpersonenFacturatie([]);
            setOptionContactpersoonFacturatie('');
            currentFacturatie.fields.voornaam.value = result.contactpersonen_facturatie[0].voornaam ? result.contactpersonen_facturatie[0].voornaam : '';
            currentFacturatie.fields.achternaam.value = result.contactpersonen_facturatie[0].achternaam ? result.contactpersonen_facturatie[0].achternaam : '';
            currentFacturatie.fields.telefoonnummer.value = result.contactpersonen_facturatie[0].telefoonnummer ? result.contactpersonen_facturatie[0].telefoonnummer : '';
            currentFacturatie.fields.emailadres.value = result.contactpersonen_facturatie[0].email ? result.contactpersonen_facturatie[0].email : '';
            currentFacturatie.fields.mobiel.value = result.contactpersonen_facturatie[0].mobiel ? result.contactpersonen_facturatie[0].mobiel : '';
            currentFacturatie.fields.functie.value = result.contactpersonen_facturatie[0].functie ? result.contactpersonen_facturatie[0].functie : '';
            currentFacturatie.valid = isFormValid(currentFacturatie.fields);
            setFacturatie(currentFacturatie)
        } else {
            currentFacturatie.fields.voornaam.value = '';
            currentFacturatie.fields.achternaam.value = '';
            currentFacturatie.fields.telefoonnummer.value = '';
            currentFacturatie.fields.emailadres.value = '';
            currentFacturatie.fields.mobiel.value = '';
            currentFacturatie.fields.functie.value = '';

            const arrContactpersonen = [];
            result.contactpersonen_facturatie.map((item, index) =>  {
                const obj = {
                    value: item.id,
                    label: item.voornaam + ' ' + item.achternaam
                }
                arrContactpersonen.push(obj);
            });
            setArrayContactpersonenFacturatie(result.contactpersonen_facturatie);
            setOptionsContactpersonenFacturatie(arrContactpersonen);
        }
        current.valid = isFormValid(current.fields);
        setKlantGegevens(current);
    }

    const setContactpersoon = (type, value) => {
       if (type === 'contactpersoon') {
            if (!value) {
                emptyKlantData([
                    'voornaam', 
                    'achternaam', 
                    'telefoonnummer', 
                    'emailadres',
                    'mobiel',
                    'functie'
                ]);
                setOptionContactpersoon('');
                return;
            }
            var current = {...klantGegevens};
            setOptionContactpersoon(value);
            var index = arrayContactpersonen.findIndex(item => item.id === value.value);
       } else {
            if (!value) {
                emptyFacturatieData([
                    'voornaam', 
                    'achternaam', 
                    'telefoonnummer', 
                    'emailadres',
                    'mobiel',
                    'functie'
                ]);
                setOptionContactpersoonFacturatie('');
                return;
            }
            var current = {...facturatie};
            setOptionContactpersoonFacturatie(value);
            var index = arrayContactpersonen.findIndex(item => item.id === value.value);
       }
        current.fields.voornaam.value = arrayContactpersonen[index].voornaam ? arrayContactpersonen[index].voornaam : '';
        current.fields.achternaam.value = arrayContactpersonen[index].achternaam ? arrayContactpersonen[index].achternaam : '';
        current.fields.telefoonnummer.value = arrayContactpersonen[index].telefoonnummer ? arrayContactpersonen[index].telefoonnummer : '';
        current.fields.emailadres.value = arrayContactpersonen[index].email ? arrayContactpersonen[index].email : '';
        current.fields.mobiel.value = arrayContactpersonen[index].mobiel ? arrayContactpersonen[index].mobiel : '';
        current.fields.functie.value = arrayContactpersonen[index].functie ? arrayContactpersonen[index].functie : '';
        current.valid = isFormValid(current.fields);

        if (type === 'contactpersoon') {
            setKlantGegevens(current);
        } else {
            setFacturatie(current);
        }
    }

    const handleZones = (value, index, subIndex) => {
        setZones((prevZones) => {
            const updatedZones = { ...prevZones };
            const postcodes = [...updatedZones.fields.zones.value[index].postcodes];
            postcodes[subIndex] = {
                ...postcodes[subIndex],
                postcode: value,
            };
            updatedZones.fields.zones.value[index].postcodes = postcodes;
            return updatedZones;
        });
    }

    const addOrRemoveZones = (type) => {
        setZones((prevZones) => {
          const current = { ...prevZones };

          if (type === 'add') {
            for (let i = 0; i < 5; i++) {
              const secondObj = Array(current.fields.zones.value[0].postcodes.length).fill(null).map(() => ({ postcode: '' }));
              current.fields.zones.value.push({
                naam: `Zone ${current.fields.zones.value.length + 1}`,
                postcodes: secondObj,
                prijzen: [],
              });
            }
          } else {
            current.fields.zones.value.splice(-5);
          }
      
          return current;
        });
    };

    const addExtraZoneRow = () => {
        const current = {...zones};
        const updatedZones = current.fields.zones.value.map(zone => ({
            ...zone,
            postcodes: [...zone.postcodes, { postcode: '' }] // Add {postcode: ''} to the end of the postcodes array
        }));
        current.fields.zones.value = updatedZones;
        setZones(current);
    }

    //Get all klanten on load
    const getKlanten = async () => {
        try {
            const response = await api.get('/klanten');
            if (response.data) {
                setData(response.data)
                const arrOptions = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.bedrijfsnaam
                    };
                   arrOptions.push(obj); 
                })
                setKlanten(arrOptions);
                getOfferte();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getMedewerkers = async () => {
        try {
            const response = await api.get('/medewerkers');
            if (response.data) {
                const arrOptions = [];
                response.data.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.attributes.voornaam + ' ' + item.attributes.achternaam
                    };
                   arrOptions.push(obj); 
                })
                setMedewerkers(arrOptions);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getDivisies = async () => {
        try {
            const response = await api.get('/divisies');
            if (response.data) {
                const arrOptions = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.label,
                        voorwoord: item.template_voorwoord
                    };
                   arrOptions.push(obj); 
                })
                setSoortenTransport(arrOptions);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getMaatstaven = async () => {
        try {
            const response = await api.get('/maatstaven?sort[0]=order');
            if (response.data) {
                const arrOptions = [];
                response.data.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.attributes.naam
                    };
                   arrOptions.push(obj); 
                })
                setMaatstaven(arrOptions);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getInhouden = async () => {
        try {
            const response = await api.get('/inhouden');
            if (response.data) {
                const arrOptions = [];
                response.data.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.attributes.code
                    };
                   arrOptions.push(obj); 
                })
                setInhouden(arrOptions);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getLanden = async () => {
        try {
            const response = await api.get('/landen');
            if (response.data) {
                const arrOptions = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.label,
                        image_url: environment + '' + item.kaart.url
                    };
                   arrOptions.push(obj); 
                })
                setLanden(arrOptions);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getToeslagen = async () => {
        try {
            const response = await api.get('/toeslagen?sort=order');
            if (response.data) {
                const arrTijdsOptions = [];
                const arrDouaneOptions = [];
                const arrAfstapelOptions = [];
                response.data.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.attributes.label
                    };
                    if (item.attributes.type === 'tijd') {
                        arrTijdsOptions.push(obj); 
                    } else if (item.attributes.type === 'afstapel') {
                        arrAfstapelOptions.push(obj);
                    } else {
                        arrDouaneOptions.push(obj);
                    }
                   
                })
                setTijdsToeslagen(arrTijdsOptions);
                setDouaneToeslagen(arrDouaneOptions);
                setAfstapelToeslagen(arrAfstapelOptions);
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getBijlagen = async () => {
        try {
            const response = await api.get('/bijlagen');
            if (response.data) {
                const arrOptions = [];
                response.data.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.attributes.naam
                    };
                   arrOptions.push(obj); 
                })
                setBijlagenOptions(arrOptions);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getDieseltoeslagen = async () => {
        try {
            const response = await api.get('/dieseltoeslagen?sort[0]=order');
            if (response.data) {
                const arrDieselToeslagen = [];
                response.data.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.attributes.label
                    };
                    arrDieselToeslagen.push(obj); 
                })
                setDieselToeslagenOptions(arrDieselToeslagen);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getOfferteSoorten = async () => {
        try {
            const response = await api.get('/offerte-soorten?sort[0]=naam');
            if (response.data) {
                const arrOfferteSoorten = [];
                response.data.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.attributes.naam
                    };
                    arrOfferteSoorten.push(obj); 
                })
                setOfferteSoorten(arrOfferteSoorten);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const addEmptyZoneRows = (offerteZones, number) => {
        if (offerteZones.length === 0) {
            while (offerteZones.length < number) {
                offerteZones.push({
                    naam: 'Zone ' + (offerteZones.length + 1),
                    postcodes: [{postcode: ''},{postcode: ''},{postcode: ''},{postcode: ''},{postcode: ''}],
                    prijzen: []
                });
            }
        } else {
            const objPostcodes = [];
            offerteZones[0].postcodes.map((item, index) =>  {
                objPostcodes.push({postcode: ''})
            })
            while (offerteZones.length < number) {
                offerteZones.push({
                    naam: 'Zone ' + (offerteZones.length + 1),
                    postcodes: objPostcodes,
                    prijzen: []
                });
            }
        }
        return offerteZones;
    }

    const getOfferte = async () => {
        try {
            const response = await api.get('/offertes/' + offerteId);
            if (response.data) {
                setOfferteData(response.data);
                setEditableRows(response.data.rijen_inhouden.some(subArray => subArray.some(obj => obj.editable === true)));
                setOfferteLogs(response.data.logs);
                const klant = {value: response.data.klant.id, label: response.data.klant.bedrijfsnaam};
                const vestiging = response.data.vestiging ? {value: response.data.vestiging.id, label: response.data.vestiging.naam} : {value: '', label: ''};
                handleChange(klantGegevens, setKlantGegevens, 'klant', response.data.klant.id);
                const arrOptions = [];
                if (response.data.klant.vestigingen.length > 0) {
                    response.data.klant.vestigingen.map((item, index) =>  {
                        const obj = {
                            value: item.id,
                            label: item.naam
                        };
                        arrOptions.push(obj); 
                    })
                }
                setVestigingen(arrOptions);
                if (response.data.vestiging) {
                    handleChange(klantGegevens, setKlantGegevens, 'vestiging', vestiging);
                }
                if (response.data.accountmanager) {
                    const accountmanager = {value: response.data.accountmanager.id, label: response.data.accountmanager.voornaam + ' ' + response.data.accountmanager.achternaam};
                    handleChange(klantGegevens, setKlantGegevens, 'accountmanager', accountmanager);
                }
                handleChange(klantGegevens, setKlantGegevens, 'omzetschatting', response.data.omzetschatting);
                handleChange(klantGegevens, setKlantGegevens, 'bedrijfsnaam', klant);
                handleChange(klantGegevens, setKlantGegevens, 'kvk_nummer', response.data.kvk_nummer);
                handleChange(klantGegevens, setKlantGegevens, 'btw_nummer', response.data.btw_nummer);
                handleChange(klantGegevens, setKlantGegevens, 'postcode', response.data.postcode);
                handleChange(klantGegevens, setKlantGegevens, 'straatnaam', response.data.straatnaam);
                handleChange(klantGegevens, setKlantGegevens, 'plaatsnaam', response.data.plaatsnaam);
                handleChange(klantGegevens, setKlantGegevens, 'accountnummer', response.data.klant.accountnummer);
                handleChange(klantGegevens, setKlantGegevens, 'voornaam', response.data.contactpersoon ? response.data.contactpersoon.voornaam : '');
                handleChange(klantGegevens, setKlantGegevens, 'achternaam', response.data.contactpersoon ? response.data.contactpersoon.achternaam : '');
                handleChange(klantGegevens, setKlantGegevens, 'telefoonnummer', response.data.contactpersoon ? response.data.contactpersoon.telefoonnummer : '');
                handleChange(klantGegevens, setKlantGegevens, 'emailadres', response.data.contactpersoon ? response.data.contactpersoon.emailadres : '');
                handleChange(klantGegevens, setKlantGegevens, 'mobiel', response.data.contactpersoon ? response.data.contactpersoon.mobiel : '');
                handleChange(klantGegevens, setKlantGegevens, 'functie', response.data.contactpersoon ? response.data.contactpersoon.functie : '');

                const divisie = {value: response.data.divisie.id, label: response.data.divisie.label};
                handleChange(transportGegevens, setTransportGegevens, 'soort_transport', divisie);
                if (response.data.ftl && response.data.ltl) {
                    var value_ftl_ltl = {value: 'ftl_ltl', label: 'FTL en LTL'};
                } else if (response.data.ftl && !response.data.ltl) {
                    var value_ftl_ltl = {value: 'ftl', label: 'FTL'};
                } else if (response.data.twee_ltl) {
                    var value_ftl_ltl = {value: 'ltl_ltl', label: '2x LTL'};

                } else {
                    var value_ftl_ltl = {value: 'ltl', label: 'LTL'};
                }
                handleChange(transportGegevens, setTransportGegevens, 'ftl_ltl', value_ftl_ltl);
                if (value_ftl_ltl === 'ftl') {
                    const currentLading = [...lading];
                    currentLading.valid = true;
                    setLading(currentLading);
                }
                if (value_ftl_ltl === 'ltl_ltl') {
                    const currentLading = {...lading};
                    currentLading.fields.ladingtype_twee.required = true;
                    currentLading.fields.minimale_inhoud_twee.required = true;
                    currentLading.fields.maximale_inhoud_twee.required = true;
                    currentLading.fields.stappen_twee.required = true;
                    setLading(currentLading);
                    currentLading.valid = isFormValid(currentLading.fields);
                }

                if (response.data.aangemaakt_voor) {
                    const opgesteldVoor = {value: response.data.aangemaakt_voor.id, label: response.data.aangemaakt_voor.voornaam + ' ' + response.data.aangemaakt_voor.achternaam};
                    handleChange(offerteGegevens, setOfferteGegevens, 'opgesteld_voor', opgesteldVoor);
                }
                if (response.data.soort_offerte) {
                    const soortOfferte = {value: response.data.soort_offerte.id, label: response.data.soort_offerte.naam};
                    handleChange(offerteGegevens, setOfferteGegevens, 'soort_offerte', soortOfferte);
                }
                // handleChange(offerteGegevens, setOfferteGegevens, 'opgesteld_door', response.data.opgesteld_door);
                handleChange(offerteGegevens, setOfferteGegevens, 'geldig_van', response.data.geldig_van);
                handleChange(offerteGegevens, setOfferteGegevens, 'geldig_tot', response.data.geldig_tot);
                handleChange(offerteGegevens, setOfferteGegevens, 'betaaltermijn', response.data.betalingstermijn);

                handleChange(facturatie, setFacturatie, 'voornaam', response.data.contactpersoon_facturatie.voornaam);
                handleChange(facturatie, setFacturatie, 'achternaam', response.data.contactpersoon_facturatie.achternaam);
                handleChange(facturatie, setFacturatie, 'telefoonnummer', response.data.contactpersoon_facturatie.telefoonnummer);
                handleChange(facturatie, setFacturatie, 'emailadres', response.data.contactpersoon_facturatie.emailadres);
                handleChange(facturatie, setFacturatie, 'mobiel', response.data.contactpersoon_facturatie.mobiel);
                handleChange(facturatie, setFacturatie, 'functie', response.data.contactpersoon_facturatie.functie);

                handleChange(begeleidendSchrijven, setBegeleidendSchrijven, 'aanhef', response.data.aanhef);
                handleChange(begeleidendSchrijven, setBegeleidendSchrijven, 'begeleidend_schrijven', response.data.omschrijving);

                const ladingtype = {value: response.data.ladingtype.id, label: response.data.ladingtype.naam};
                handleChange(lading, setLading, 'ladingtype', ladingtype);
                handleChange(lading, setLading, 'minimale_inhoud', response.data.minimale_inhoud);
                handleChange(lading, setLading, 'maximale_inhoud', response.data.maximale_inhoud);
                handleChange(lading, setLading, 'stappen', response.data.stappen);

                var ladingtype_twee = null;
                if (response.data.ladingtype_twee && response.data.twee_ltl) {
                    ladingtype_twee = {value: response.data.ladingtype_twee.id, label: response.data.ladingtype_twee.naam};
                    handleChange(lading, setLading, 'ladingtype_twee', ladingtype_twee);
                    handleChange(lading, setLading, 'minimale_inhoud_twee', response.data.minimale_inhoud_twee);
                    handleChange(lading, setLading, 'maximale_inhoud_twee', response.data.maximale_inhoud_twee);
                    handleChange(lading, setLading, 'stappen_twee', response.data.stappen_twee);
                }
                

                const arrayLadingsoorten = [];
                const arrayInhouden = [];
                response.data.ladingsoorten.map((item, index) =>  {
                    arrayLadingsoorten.push(item.id);
                    arrayInhouden.push({value: item.id, label: item.code});
                })
                setKolomOptions(arrayInhouden);
                handleChange(lading, setLading, 'inhouden', arrayLadingsoorten);
                if (response.data.kolommen_tonen.length > 0) {
                    var arrayKolommenTonen = [];
                    response.data.kolommen_tonen.map((item, index) =>  {
                        arrayKolommenTonen.push({value: item.id, label: item.code});
                    })
                    handleChange(lading, setLading, 'kolommen_tonen', arrayKolommenTonen);
                }
                handleChange(lading, setLading, 'toon_gewicht', response.data.toon_gewicht ? "true" : "false");

                const objInfoMaatstaven = {
                    ladingtype: ladingtype,
                    minimale_inhoud: response.data.minimale_inhoud,
                    maximale_inhoud: response.data.maximale_inhoud,
                    stappen: response.data.stappen,
                    ladingtype_twee: ladingtype_twee,
                    minimale_inhoud_twee: response.data.minimale_inhoud_twee,
                    maximale_inhoud_twee: response.data.maximale_inhoud_twee,
                    stappen_twee: response.data.stappen_twee,
                    inhouden: arrayLadingsoorten,
                    kolommen_tonen: arrayKolommenTonen,
                    toon_gewicht: response.data.toon_gewicht ? "true" : "false"
                };
                setInfoMaatstaven(objInfoMaatstaven);

                if (response.data.land) {
                    const landValues = {value: response.data.land.id, label: response.data.land.label, image_url: environment + '' + response.data.land.kaart.url};
                    handleChange(land, setLand, 'bestemming', landValues);
                }
                
                handleChange(land, setLand, 'laadmeter_gewicht', response.data.laadmeter_gewicht);
                setInitialLaadmeterGewicht(response.data.laadmeter_gewicht);

                if (response.data.toeslagen.length > 0) {
                    const arrayTijdstoeslagen = [];
                    const arrayDouanetoeslagen = [];
                    const arrAfstapeltoeslagen = [];
                    response.data.toeslagen.map((item, index) =>  {
                        if (item.toeslag.type === 'tijd') {
                            arrayTijdstoeslagen.push({soort_toeslag: {value: item.toeslag.id, label: item.toeslag.label}, eenheid: item.eenheid, per_eenheid: item.per_eenheid, opmerking: item.opmerking});
                        }
                        if (item.toeslag.type === 'douane') {
                            arrayDouanetoeslagen.push({soort_toeslag: {value: item.toeslag.id, label: item.toeslag.label}, eenheid: item.eenheid, per_eenheid: item.per_eenheid, opmerking: item.opmerking});
                        }
                        if (item.toeslag.type === 'afstapel') {
                            arrAfstapeltoeslagen.push({soort_toeslag: {value: item.toeslag.id, label: item.toeslag.label}, eenheid: item.eenheid, per_eenheid: item.per_eenheid, opmerking: item.opmerking});
                        }
                    })
                    if (arrayTijdstoeslagen.length === 0) {
                        arrayTijdstoeslagen.push({soort_toeslag: '', eenheid: '', per_eenheid: '', opmerking: ''});
                    }
                    if (arrayDouanetoeslagen.length === 0) {
                        arrayDouanetoeslagen.push({soort_toeslag: '', eenheid: '', per_eenheid: '', opmerking: ''});
                    }
                    if (arrAfstapeltoeslagen.length === 0) {
                        arrAfstapeltoeslagen.push({soort_toeslag: '', eenheid: '', per_eenheid: '', opmerking: ''});
                    }
                    const currentToeslagen = {...toeslagen};
                    currentToeslagen.fields.tijdstoeslagen.value = arrayTijdstoeslagen; 
                    currentToeslagen.fields.douanetoeslagen.value = arrayDouanetoeslagen;
                    currentToeslagen.fields.afstapeltoeslagen.value = arrAfstapeltoeslagen; 
                    currentToeslagen.valid = isFormValid(currentToeslagen);
                    setToeslagen(currentToeslagen);
                }
                handleChange(toeslagen, setToeslagen, 'overige_afspraken', response.data.overige_afspraken);

                handleChange(dieseltoeslagen, setDieseltoeslagen, 'dieseltoeslag', response.data.dieseltoeslag ? "true" : "false");
                if (response.data.dieseltoeslag) {
                    const soortDieseltoeslag = {value: response.data.soort_dieseltoeslag.id, label: response.data.soort_dieseltoeslag.label};
                    handleChange(dieseltoeslagen, setDieseltoeslagen, 'soort_dieseltoeslag', soortDieseltoeslag);
                }

                const allBijlagen = [];
                response.data.bijlagen.map((item, index) =>  {
                    allBijlagen.push(item.id);
                })
                handleChange(bijlagen, setBijlagen, 'bijlagen', allBijlagen);

                // Find the length of the longest postcodes array
                const maxLength = Math.max(...response.data.zones.map(item => item.postcodes.length));
                // Iterate through the array and update the postcodes arrays
                var offerteZones = response.data.zones.map(item => ({
                    ...item,
                    postcodes: item.postcodes.concat(
                        Array(maxLength - item.postcodes.length).fill({ postcode: '' })
                    ),
                }));

                if (offerteZones.length < 5) {
                    offerteZones = addEmptyZoneRows(offerteZones, 5);
                }
                if (offerteZones.length > 5 && offerteZones.length < 10) {
                    offerteZones = addEmptyZoneRows(offerteZones, 10);
                }
                if (offerteZones.length > 10 && offerteZones.length < 15) {
                    offerteZones = addEmptyZoneRows(offerteZones, 15);
                }
                if (offerteZones.length > 15 && offerteZones.length < 20) {
                    offerteZones = addEmptyZoneRows(offerteZones, 20);
                }
                handleChange(zones, setZones, 'zones', offerteZones);
                setInitialZones(deepCopy(offerteZones));

                handleChange(divisieVariabelen, setDivisieVariabelen, 'bedrag_wachtkosten', response.data.divisie_variabelen ? response.data.divisie_variabelen.bedrag_wachtkosten : '');
                handleChange(divisieVariabelen, setDivisieVariabelen, 'percentage_waddentoeslag', response.data.divisie_variabelen ? response.data.divisie_variabelen.percentage_waddentoeslag : '');
                handleChange(divisieVariabelen, setDivisieVariabelen, 'percentage_doorleveringen', response.data.divisie_variabelen ? response.data.divisie_variabelen.percentage_doorleveringen : '');
                handleChange(divisieVariabelen, setDivisieVariabelen, 'percentage_tweede_levering', response.data.divisie_variabelen ? response.data.divisie_variabelen.percentage_tweede_levering : '');
                handleChange(divisieVariabelen, setDivisieVariabelen, 'percentage_retourneren', response.data.divisie_variabelen ? response.data.divisie_variabelen.percentage_retourneren : '');
                handleChange(divisieVariabelen, setDivisieVariabelen, 'tijdstip_opgave_orders', response.data.divisie_variabelen ? response.data.divisie_variabelen.tijdstip_opgave_orders : '');
                handleChange(divisieVariabelen, setDivisieVariabelen, 'tijdstip_zendingen_beschikbaarheid', response.data.divisie_variabelen ? response.data.divisie_variabelen.tijdstip_zendingen_beschikbaarheid : '');
                handleChange(divisieVariabelen, setDivisieVariabelen, 'percentage_europallets', response.data.divisie_variabelen ? response.data.divisie_variabelen.percentage_europallets : '');
                handleChange(divisieVariabelen, setDivisieVariabelen, 'aantal_dagen_reclames', response.data.divisie_variabelen ? response.data.divisie_variabelen.aantal_dagen_reclames : '');
                
                setIsLoadingOfferte(false);

            }
        } catch (error) {}
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const checkChanges = () => {

        const ladingtypeTweeMaatstaven = infoMaatstaven.ladingtype_twee ? JSON.stringify(infoMaatstaven.ladingtype_twee) : null;
        const ladingtypeTwee = lading.fields.ladingtype_twee.value ? JSON.stringify(lading.fields.ladingtype_twee.value) : null;
        
        if (
            JSON.stringify(infoMaatstaven.inhouden) !== JSON.stringify(lading.fields.inhouden.value) 
            || JSON.stringify(infoMaatstaven.kolommen_tonen) !== JSON.stringify(lading.fields.kolommen_tonen.value)
            || JSON.stringify(infoMaatstaven.ladingtype) !== JSON.stringify(lading.fields.ladingtype.value)
            || infoMaatstaven.minimale_inhoud !== Number(lading.fields.minimale_inhoud.value)
            || infoMaatstaven.maximale_inhoud !== Number(lading.fields.maximale_inhoud.value)
            || infoMaatstaven.stappen !== Number(lading.fields.stappen.value)
            || ladingtypeTweeMaatstaven !== ladingtypeTwee
            || (infoMaatstaven.minimale_inhoud_twee && infoMaatstaven.minimale_inhoud_twee !== Number(lading.fields.minimale_inhoud_twee.value))
            || (infoMaatstaven.maximale_inhoud_twee && infoMaatstaven.maximale_inhoud_twee !== Number(lading.fields.maximale_inhoud_twee.value))
            || (infoMaatstaven.stappen_twee &&infoMaatstaven.stappen_twee !== Number(lading.fields.stappen_twee.value))
            || infoMaatstaven.toon_gewicht !== lading.fields.toon_gewicht.value
            || JSON.stringify(zones.fields.zones.value) !== JSON.stringify(initialZones)
            || land.fields.laadmeter_gewicht.value !== initialLaadmeterGewicht
        ) {
            if (editableRows) {
                confirmSaveOfferte(true);
            } else {
                saveOfferte(true);
            }
            
        } else {
            saveOfferte(false);
        }
    }

    const confirmSaveOfferte = (maatstavenChanged) => {
        confirmAlert({
            title: 'Je hebt een wijziging doorgevoerd in lading maatstaaf en/of zones en er zijn handmatig rijen aan de prijslijst toegvoegd. Als je doorgaat zal je deze opnieuw moeten toevoegen',
            message: 'Wil je doorgaan?',
            buttons: [
              {
                label: 'Ja',
                onClick: () => saveOfferte(maatstavenChanged)
              },
              {
                label: 'Nee'
              }
            ]
          });
    }

    const saveOfferte = async (maatstavenChanged) => {

        var submitableZones = [];
        submitableZones = zones.fields.zones.value.map(item => ({
            naam: item.naam,
            postcodes: item.postcodes.filter(postcodeObj => postcodeObj.postcode !== ''),
            prijzen: item.prijzen,
        }))
        .filter(item => item.postcodes.length > 0);

        var submitableZonesTwee = [];
        submitableZonesTwee = zones.fields.zones.value.map((item, index) => ({
            naam: item.naam,
            postcodes: item.postcodes.filter(postcodeObj => postcodeObj.postcode !== ''),
            prijzen: offerteData.zones_twee[index] ? offerteData.zones_twee[index].prijzen : item.prijzen
        }))
        .filter(item => item.postcodes.length > 0);

        const submitableKolommen = [];
        lading.fields.kolommen_tonen.value.map((item, index) =>  {
            submitableKolommen.push(item.value);
        })

        const submitableToeslagen = [];
        toeslagen.fields.tijdstoeslagen.value.map((item, index) =>  {
            const toeslagenObj = {
                toeslag: { id: item.soort_toeslag ? item.soort_toeslag.value : '' },
                eenheid: item.eenheid,
                per_eenheid: Number(item.per_eenheid),
                opmerking: item.opmerking
            }
            if (item.soort_toeslag) {
                submitableToeslagen.push(toeslagenObj);
            }
        })
        toeslagen.fields.douanetoeslagen.value.map((item, index) =>  {
            const toeslagenObj = {
                toeslag: { id: item.soort_toeslag ? item.soort_toeslag.value : '' },
                eenheid: item.eenheid,
                per_eenheid: Number(item.per_eenheid),
                opmerking: item.opmerking
            }
            if (item.soort_toeslag) {
                submitableToeslagen.push(toeslagenObj);
            }
        })
        toeslagen.fields.afstapeltoeslagen.value.map((item, index) =>  {
            const toeslagenObj = {
                toeslag: { id: item.soort_toeslag ? item.soort_toeslag.value : '' },
                eenheid: item.eenheid,
                per_eenheid: Number(item.per_eenheid),
                opmerking: item.opmerking
            }
            if (item.soort_toeslag) {
                submitableToeslagen.push(toeslagenObj);
            }
        })

        const log = {
            "omschrijving": 'Offerte gewijzigd door ' + medewerker.voornaam + ' ' + medewerker.achternaam,
            "datum_tijd": new Date().toISOString(),
        };
        const newLog = offerteLogs;
        newLog.unshift(log);

        const obj = {
            data: {
                klant: { id: klantGegevens.fields.klant.value },
                maatstaven_changed: maatstavenChanged,
                soort_offerte: { id: offerteGegevens.fields.soort_offerte.value.value },
                accountmanager: { id: klantGegevens.fields.accountmanager.value.value },
                omzetschatting: klantGegevens.fields.omzetschatting.value ? klantGegevens.fields.omzetschatting.value : 0,
                vestiging: {id: klantGegevens.fields.vestiging.value.value },
                kvk_nummer: klantGegevens.fields.kvk_nummer.value,
                btw_nummer: klantGegevens.fields.btw_nummer.value,
                postcode: klantGegevens.fields.postcode.value,
                straatnaam: klantGegevens.fields.straatnaam.value,
                plaatsnaam: klantGegevens.fields.plaatsnaam.value,
                contactpersoon: { 
                    voornaam: klantGegevens.fields.voornaam.value,
                    achternaam: klantGegevens.fields.achternaam.value,
                    emailadres: klantGegevens.fields.emailadres.value,
                    functie: klantGegevens.fields.functie.value,
                    mobiel: klantGegevens.fields.mobiel.value,
                    telefoonnummer: klantGegevens.fields.telefoonnummer.value
                },
                divisie: { id: transportGegevens.fields.soort_transport.value.value },
                datum: offerteGegevens.fields.datum.value,
                aangemaakt_voor: { id: offerteGegevens.fields.opgesteld_voor.value.value },
                // opgesteld_door: offerteGegevens.fields.opgesteld_door.value,
                geldig_van: offerteGegevens.fields.geldig_van.value,
                geldig_tot: offerteGegevens.fields.geldig_tot.value,
                betalingstermijn: offerteGegevens.fields.betaaltermijn.value,
                contactpersoon_facturatie: {
                    voornaam: facturatie.fields.voornaam.value,
                    achternaam: facturatie.fields.achternaam.value,
                    emailadres: facturatie.fields.emailadres.value,
                    functie: facturatie.fields.functie.value,
                    mobiel: facturatie.fields.mobiel.value,
                    telefoonnummer: facturatie.fields.telefoonnummer.value
                },
                aanhef: begeleidendSchrijven.fields.aanhef.value,
                omschrijving: begeleidendSchrijven.fields.begeleidend_schrijven.value,
                ladingtype: { id: lading.fields.ladingtype.value.value },
                minimale_inhoud: Number(lading.fields.minimale_inhoud.value),
                maximale_inhoud: Number(lading.fields.maximale_inhoud.value),
                stappen: Number(lading.fields.stappen.value),
                ladingtype_twee: lading.fields.ladingtype_twee.value ? { id: lading.fields.ladingtype_twee.value.value } : null,
                minimale_inhoud_twee: lading.fields.minimale_inhoud_twee.value ? Number(lading.fields.minimale_inhoud_twee.value) : 0,
                maximale_inhoud_twee: lading.fields.maximale_inhoud_twee.value ? Number(lading.fields.maximale_inhoud_twee.value) : 0,
                stappen_twee: lading.fields.stappen_twee.value ? Number(lading.fields.stappen_twee.value) : 0,
                ladingsoorten: lading.fields.inhouden.value,
                kolommen_tonen: submitableKolommen,
                toon_gewicht: lading.fields.toon_gewicht.value,
                land: land.fields.bestemming.value.value ? {id: land.fields.bestemming.value.value } : null,
                zones: submitableZones,
                zones_twee: submitableZonesTwee,
                laadmeter_gewicht: land.fields.laadmeter_gewicht.value,
                toeslagen: submitableToeslagen,
                overige_afspraken: toeslagen.fields.overige_afspraken.value,
                dieseltoeslag: dieseltoeslagen.fields.dieseltoeslag.value,
                soort_dieseltoeslag: {id: dieseltoeslagen.fields.soort_dieseltoeslag.value.value },
                bijlagen: bijlagen.fields.bijlagen.value,
                divisie_variabelen: {
                    bedrag_wachtkosten: divisieVariabelen.fields.bedrag_wachtkosten.value,
                    percentage_waddentoeslag: divisieVariabelen.fields.percentage_waddentoeslag.value,
                    percentage_doorleveringen: divisieVariabelen.fields.percentage_doorleveringen.value,
                    percentage_tweede_levering: divisieVariabelen.fields.percentage_tweede_levering.value,
                    percentage_retourneren: divisieVariabelen.fields.percentage_retourneren.value,
                    tijdstip_opgave_orders: divisieVariabelen.fields.tijdstip_opgave_orders.value,
                    tijdstip_zendingen_beschikbaarheid: divisieVariabelen.fields.tijdstip_zendingen_beschikbaarheid.value,
                    percentage_europallets: divisieVariabelen.fields.percentage_europallets.value,
                    aantal_dagen_reclames: divisieVariabelen.fields.aantal_dagen_reclames.value
                },
                logs: newLog
            }
        };

        if (transportGegevens.fields.ftl_ltl.value.value === 'ftl') {
            obj.data.ftl = true;
            obj.data.ltl = false;
            obj.data.twee_ltl = false;
        } else if (transportGegevens.fields.ftl_ltl.value.value === 'ltl') {
            obj.data.ltl = true;
            obj.data.twee_ltl = false;
            obj.data.ftl = false;
        } else if (transportGegevens.fields.ftl_ltl.value.value === 'ltl_ltl') {
            obj.data.ftl = false;
            obj.data.ltl = false;
            obj.data.twee_ltl = true;
        } else {
            obj.data.ftl = true;
            obj.data.ltl = true;
            obj.data.twee_ltl = false;
        }

        setSavingOfferte(true);
        await delay(2500);

        try {
            const response = await api.put('/offertes/' + offerteId, obj);
            if (response.data) {
                notifySuccess('Offerte is bijgewerkt');
                setSavingOfferte(false);
                navigate('/openstaand');
            }
        } catch (error) {
            console.log(error);
        }
    }




    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getKlanten();
        getMedewerkers();
        getDivisies();
        getMaatstaven();
        getInhouden();
        getLanden();
        getToeslagen();
        getBijlagen();
        getOfferte();
        getDieseltoeslagen();
        getOfferteSoorten();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/offerte-bewerken" />
        <div className="dashboard-content">
            <DashboardHeader title="Offerte bewerken" />

            {
            isLoadingOfferte ?
            <p>Laden van offerte</p>
            :
            <div id="offerte-form">
                <div className="form-content">

                    {/* Stap 1a */}
                    <div id={klantGegevens.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{klantGegevens.label}</h3>{klantGegevens.icon}</div>

                            {/* Basisgegevens */}
                            <div className="sub-block">
                                <h4>Basisgegevens</h4>
                                <div className="two-column">
                                    <div className="input-field select-field">
                                        <Select 
                                            options={klanten} 
                                            className={klantGegevens.fields.bedrijfsnaam.value ? 'select-option valid' : 'select-option'} 
                                            placeholder={klantGegevens.fields.bedrijfsnaam.label + (klantGegevens.fields.bedrijfsnaam.required ? '*' : '')} value={klantGegevens.fields.bedrijfsnaam.value} 
                                            onChange={(e) => {handleChange(klantGegevens, setKlantGegevens, 'bedrijfsnaam', e); setKlantData(e);}} 
                                            isClearable
                                            disabled={isLoading}
                                        />
                                        { klantGegevens.fields.bedrijfsnaam.value && ( <span className="placeholder">{klantGegevens.fields.bedrijfsnaam.label + (klantGegevens.fields.bedrijfsnaam.required ? '*' : '')}</span>) }
                                    </div>
                                    <div className="input-field select-field">
                                        <Select 
                                            options={vestigingen} 
                                            className={klantGegevens.fields.vestiging.value ? 'select-option valid' : 'select-option'} 
                                            placeholder={klantGegevens.fields.vestiging.label + (klantGegevens.fields.vestiging.required ? '*' : '')} value={klantGegevens.fields.vestiging.value}
                                            onChange={(e) => {handleChange(klantGegevens, setKlantGegevens, 'vestiging', e);}} 
                                            isClearable
                                            disabled={isLoading}
                                        />
                                        { klantGegevens.fields.vestiging.value && ( <span className="placeholder">{klantGegevens.fields.vestiging.label + (klantGegevens.fields.vestiging.required ? '*' : '')}</span>) }
                                        
                                    </div>
                                </div>
                            </div>

                            {/* Omzetschatting */}
                            <div className="sub-block">
                                <h4>Accountmanager en omzetschatting</h4>
                                <div className="two-column">
                                    <Select 
                                        options={medewerkers} 
                                        className={klantGegevens.fields.accountmanager.value ? 'select-option valid' : 'select-option'} 
                                        placeholder={klantGegevens.fields.accountmanager.label + (klantGegevens.fields.accountmanager.required ? '*' : '')} value={klantGegevens.fields.accountmanager.value} 
                                        onChange={(e) => handleChange(klantGegevens, setKlantGegevens, 'accountmanager', e)} 
                                        isClearable
                                        disabled={isLoading}
                                    />
                                    <input type="number" onKeyDown={ (event) => (event.key === '.' || event.key === ',') && event.preventDefault() } value={klantGegevens.fields.omzetschatting.value} placeholder={klantGegevens.fields.omzetschatting.label} className={klantGegevens.fields.omzetschatting.value ? 'form-field input-field valid' : 'form-field input-field'} onChange={(e) => handleChange(klantGegevens, setKlantGegevens, 'omzetschatting', e.target.value)} />
                                </div>
                            </div>

                            {/* Bedrijfsgegevens */}
                            <div className="sub-block">
                                <h4>Bedrijfsgegevens</h4>
                                <div className="two-column">
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'kvk_nummer'}handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'btw_nummer'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                            </div>

                            {/* Adres */}
                            <div className="sub-block">
                                <h4>Adres</h4>
                                <div className="two-column">
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'postcode'}handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'straatnaam'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <InputField section={klantGegevens} state={setKlantGegevens} field={'plaatsnaam'}handleChange={handleChange} isLoading={isLoading} />
                            </div>

                            {/* Accountnummer */}
                            <div className="sub-block">
                                <h4>Accountnummer</h4>
                                <InputField section={klantGegevens} state={setKlantGegevens} field={'accountnummer'}handleChange={handleChange} isLoading={isLoading} />
                            </div>

                            {/* Contactpersoon */}
                            <div className="sub-block">
                                <h4>Contactpersoon</h4>
                                {
                                    optionsContactpersonen.length > 0 ?
                                    <div className="input-field select-field">
                                        <Select 
                                            options={optionsContactpersonen} 
                                            className={'select-option'} 
                                            placeholder={'Selecteer contactpersoon'} value={optionContactpersoon} 
                                            onChange={(e) => {setContactpersoon('contactpersoon', e)}} 
                                            isClearable
                                            disabled={isLoading}
                                        />
                                        { optionContactpersoon && ( <span className="placeholder">{'Selecteer contactpersoon'}</span>) }
                                    </div>
                                    :
                                    null
                                }
                                <div className="two-column">
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'voornaam'}handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'achternaam'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <div className="two-column">
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'telefoonnummer'}handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'emailadres'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <div className="two-column">
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'mobiel'}handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={klantGegevens} state={setKlantGegevens} field={'functie'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                            </div>



                        </div>
                    </div>


                    {/* Stap 1b */}
                    <div id={transportGegevens.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{transportGegevens.label}</h3>{transportGegevens.icon}</div>

                            {/* Basisgegevens */}
                            <div className="sub-block">
                                <div className="two-column">
                                    <div className="input-field select-field">
                                        <Select 
                                            options={soortenTransport} 
                                            className={transportGegevens.fields.soort_transport.value ? 'select-option valid' : 'select-option'} 
                                            placeholder={transportGegevens.fields.soort_transport.label + (transportGegevens.fields.soort_transport.required ? '*' : '')} value={transportGegevens.fields.soort_transport.value} 
                                            onChange={(e) => handleChange(transportGegevens, setTransportGegevens, 'soort_transport', e)} 
                                            isClearable
                                            disabled={isLoading}
                                        />
                                        { transportGegevens.fields.soort_transport.value && ( <span className="placeholder">{transportGegevens.fields.soort_transport.label + (transportGegevens.fields.soort_transport.required ? '*' : '')}</span>) }
                                    </div>
                                    <div className="input-field select-field">
                                        <Select 
                                            options={optionsFtlLtl} 
                                            className={transportGegevens.fields.ftl_ltl.value ? 'select-option valid' : 'select-option'} 
                                            placeholder={transportGegevens.fields.ftl_ltl.label + (transportGegevens.fields.ftl_ltl.required ? '*' : '')} value={transportGegevens.fields.ftl_ltl.value} 
                                            onChange={(e) => handleChange(transportGegevens, setTransportGegevens, 'ftl_ltl', e)} 
                                            isClearable
                                            disabled={isLoading}
                                        />
                                        { transportGegevens.fields.ftl_ltl.value && ( <span className="placeholder">{transportGegevens.fields.ftl_ltl.label + (transportGegevens.fields.ftl_ltl.required ? '*' : '')}</span>) }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    {/* Stap 1c */}
                    <div id={offerteGegevens.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{offerteGegevens.label}</h3>{offerteGegevens.icon}</div>
                                <div className="input-field select-field">
                                    <div className="two-column">
                                        <Select 
                                            options={offerteSoorten} 
                                            className={offerteGegevens.fields.soort_offerte.value ? 'select-option valid' : 'select-option'} 
                                            placeholder={offerteGegevens.fields.soort_offerte.label + (offerteGegevens.fields.soort_offerte.required ? '*' : '')} value={offerteGegevens.fields.soort_offerte.value} 
                                            onChange={(e) => handleChange(offerteGegevens, setOfferteGegevens, 'soort_offerte', e)} 
                                            isClearable
                                            disabled={isLoading}
                                        />
                                        <Select 
                                            options={medewerkers} 
                                            className={offerteGegevens.fields.opgesteld_voor.value ? 'select-option valid' : 'select-option'} 
                                            placeholder={offerteGegevens.fields.opgesteld_voor.label + (offerteGegevens.fields.opgesteld_voor.required ? '*' : '')} value={offerteGegevens.fields.opgesteld_voor.value} 
                                            onChange={(e) => handleChange(offerteGegevens, setOfferteGegevens, 'opgesteld_voor', e)} 
                                            isClearable
                                            disabled={isLoading}
                                        />
                                    </div>
                                    { offerteGegevens.fields.opgesteld_voor.value && ( <span className="placeholder">{offerteGegevens.fields.opgesteld_voor.label + (offerteGegevens.fields.opgesteld_voor.required ? '*' : '')}</span>) }
                                </div>
                            <div className="sub-block">
                                <div className="two-column">
                                    {/* <InputField type={'text'} section={offerteGegevens} state={setOfferteGegevens} field={'opgesteld_door'} handleChange={handleChange} isLoading={isLoading} /> */}
                                    <InputField type={'date'} section={offerteGegevens} state={setOfferteGegevens} field={'datum'} handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={offerteGegevens} state={setOfferteGegevens} field={'betaaltermijn'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <div className="two-column">
                                    <InputField section={offerteGegevens} state={setOfferteGegevens} field={'geldig_van'}handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={offerteGegevens} state={setOfferteGegevens} field={'geldig_tot'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Stap 1d */}
                    <div id={facturatie.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{facturatie.label}</h3>{facturatie.icon}</div>
                            <div className="sub-block">
                                <h4>Contactpersoon facturatie</h4>
                                {
                                    optionsContactpersonenFacturatie.length > 0 ?
                                    <div className="input-field select-field">
                                        <Select 
                                            options={optionsContactpersonenFacturatie} 
                                            className={'select-option'} 
                                            placeholder={'Selecteer contactpersoon'} value={optionContactpersoonFacturatie} 
                                            onChange={(e) => {setContactpersoon('facturatie', e)}} 
                                            isClearable
                                            disabled={isLoading}
                                        />
                                        { optionContactpersoonFacturatie && ( <span className="placeholder">{'Selecteer contactpersoon'}</span>) }
                                    </div>
                                    :
                                    null
                                }
                                <div className="two-column">
                                    <InputField section={facturatie} state={setFacturatie} field={'voornaam'}handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={facturatie} state={setFacturatie} field={'achternaam'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <div className="two-column">
                                    <InputField section={facturatie} state={setFacturatie} field={'telefoonnummer'}handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={facturatie} state={setFacturatie} field={'emailadres'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <div className="two-column">
                                    <InputField section={facturatie} state={setFacturatie} field={'mobiel'}handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={facturatie} state={setFacturatie} field={'functie'}handleChange={handleChange} isLoading={isLoading} />
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Stap 2a */}
                    <div id={begeleidendSchrijven.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{begeleidendSchrijven.label}</h3>{begeleidendSchrijven.icon}</div>
                            <div className="sub-block">
                                <InputField section={begeleidendSchrijven} state={setBegeleidendSchrijven} field={'aanhef'} handleChange={handleChange} isLoading={isLoading} />
                                <textarea value={begeleidendSchrijven.fields.begeleidend_schrijven.value} placeholder={begeleidendSchrijven.fields.begeleidend_schrijven.label + (begeleidendSchrijven.fields.begeleidend_schrijven.required ? '*' : '')} className={begeleidendSchrijven.fields.begeleidend_schrijven.value ? 'form-field valid' : 'form-field'} rows="10" cols="50" onChange={(e) => handleChange(begeleidendSchrijven, setBegeleidendSchrijven, 'begeleidend_schrijven', e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>

                    {/* Stap 2b */}
                    {
                        transportGegevens.fields.ftl_ltl.value.value !== 'ftl' ?
                        <div id={lading.id} className="block">
                            <div className="content">
                                <div className="block-header"><h3>{lading.label}</h3>{lading.icon}</div>
                                <div className="sub-block">
                                    <div className="toeslagen">
                                        <div className="rows">
                                            <div className="maatstaf">
                                                <div className="field middle">
                                                    <Select 
                                                        options={maatstaven} 
                                                        className={lading.fields.ladingtype.value ? 'select-option valid' : 'select-option'} 
                                                        placeholder={'Maak een keuze'} value={lading.fields.ladingtype.value} 
                                                        onChange={(e) => handleChange(lading, setLading, 'ladingtype', e)} 
                                                        isClearable
                                                        disabled={isLoading}
                                                    />
                                                </div>
                                                <div className="field middle"><input type="number" value={lading.fields.minimale_inhoud.value} placeholder={lading.fields.minimale_inhoud.label} className={lading.fields.minimale_inhoud.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange(lading, setLading, 'minimale_inhoud', e.target.value)} /></div>
                                                <div className="field middle"><input type="number" value={lading.fields.maximale_inhoud.value} placeholder={lading.fields.maximale_inhoud.label} className={lading.fields.maximale_inhoud.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange(lading, setLading, 'maximale_inhoud', e.target.value)} /></div>
                                                <div className="field middle"><input type="number" value={lading.fields.stappen.value} placeholder={lading.fields.stappen.label} className={lading.fields.stappen.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange(lading, setLading, 'stappen', e.target.value)} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        transportGegevens.fields.ftl_ltl.value.value === 'ltl_ltl' ?
                                        <div className="toeslagen">
                                            <div className="rows">
                                                <div className="maatstaf">
                                                    <div className="field middle">
                                                        <Select 
                                                            options={maatstaven} 
                                                            className={lading.fields.ladingtype_twee.value ? 'select-option valid' : 'select-option'} 
                                                            placeholder={'Maak een keuze'} value={lading.fields.ladingtype_twee.value} 
                                                            onChange={(e) => handleChange(lading, setLading, 'ladingtype_twee', e)} 
                                                            isClearable
                                                            disabled={isLoading}
                                                        />
                                                    </div>
                                                    <div className="field middle"><input type="number" value={lading.fields.minimale_inhoud_twee.value} placeholder={lading.fields.minimale_inhoud_twee.label} className={lading.fields.minimale_inhoud_twee.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange(lading, setLading, 'minimale_inhoud_twee', e.target.value)} /></div>
                                                    <div className="field middle"><input type="number" value={lading.fields.maximale_inhoud_twee.value} placeholder={lading.fields.maximale_inhoud_twee.label} className={lading.fields.maximale_inhoud_twee.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange(lading, setLading, 'maximale_inhoud_twee', e.target.value)} /></div>
                                                    <div className="field middle"><input type="number" value={lading.fields.stappen_twee.value} placeholder={lading.fields.stappen_twee.label} className={lading.fields.stappen_twee.value ? 'form-field valid' : 'form-field'} onChange={(e) => handleChange(lading, setLading, 'stappen_twee', e.target.value)} /></div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className="checkboxes">
                                        {
                                            inhouden.map((item, index) =>  {
                                                return <div key={index} className="checkbox">
                                                    <input type="checkbox" id={'inhouden-'+ item.value} value={item.value} onChange={handleInhoudenChange} checked={lading.fields.inhouden.value.includes(item.value)} />
                                                    <label htmlFor={'inhouden-' + item.value}> {item.label}</label>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <Select 
                                        options={kolomOptions}
                                        isMulti 
                                        className={'select-option'} 
                                        placeholder={'Selecteer max. twee kolommen voor prijslijst'}
                                        defaultValue={lading.fields.kolommen_tonen.value} 
                                        onChange={(e) => {handleChange(lading, setLading, 'kolommen_tonen', e)}} 
                                        isClearable
                                        disabled={isLoading}
                                    />
                                    <div className="radio-group">
                                        <label>{lading.fields.toon_gewicht.label}</label>
                                        <div className="radio-option">
                                            <input type="radio" id="gewicht_ja" name="toon_gewicht" value="true" checked={lading.fields.toon_gewicht.value === 'true' ? true : false} onChange={(e) => handleChange(lading, setLading, 'toon_gewicht', e.target.value)} />
                                            <label htmlFor="gewicht_ja">Ja</label>
                                        </div>
                                        <div className="radio-option">
                                            <input type="radio" id="gewicht_nee" name="toon_gewicht" value="false" checked={lading.fields.toon_gewicht.value === 'true' ? false : true} onChange={(e) => handleChange(lading, setLading, 'toon_gewicht', e.target.value)} />
                                            <label htmlFor="gewicht_nee">Nee</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    


                    {/* Stap 2c */}
                    <div id={land.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{land.label}</h3>{land.icon}</div>
                            <div className="sub-block">
                                <div className="two-column">
                                    <div className="input-field select-field">
                                        <Select 
                                            options={landen} 
                                            className={land.fields.bestemming.value ? 'select-option valid' : 'select-option'} 
                                            placeholder={land.fields.bestemming.label + (land.fields.bestemming.required ? '*' : '')} value={land.fields.bestemming.value} 
                                            onChange={(e) => handleChange(land, setLand, 'bestemming', e)} 
                                            isClearable
                                            disabled={isLoading}
                                        />
                                        { land.fields.bestemming.value && ( <span className="placeholder">{land.fields.bestemming.label + (land.fields.bestemming.required ? '*' : '')}</span>) }
                                    </div>
                                    <InputField section={land} state={setLand} field={'laadmeter_gewicht'} handleChange={handleChange} isLoading={isLoading} />
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Stap 3a */}
                    <div id={toeslagen.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{toeslagen.label}</h3>{toeslagen.icon}</div>
                            <div className="sub-block">
                                <h4>Tijdstoeslagen</h4>
                                <div className="toeslagen">
                                    <div className="rows">
                                        {
                                            toeslagen.fields.tijdstoeslagen.value.map((item, index) =>  {
                                                return <div key={index} className="toeslag">
                                                    <div className="field long">
                                                    <Select 
                                                        options={tijdsToeslagen} 
                                                        className={item.value ? 'select-option valid' : 'select-option'} 
                                                        placeholder={'Maak een keuze'} value={item.soort_toeslag}
                                                        onChange={(e) => handleToeslagenChange('tijdstoeslagen', 'soort_toeslag', e, index)} 
                                                        isClearable
                                                        disabled={isLoading}
                                                    />
                                                    </div>
                                                    <div className="field short"><input type="text" value={item.eenheid} placeholder={'Eenheid'} className={'form-field'} onChange={(e) => handleToeslagenChange('tijdstoeslagen', 'eenheid', e.target.value, index)} /></div>
                                                    <div className="field short"><input type="number" value={item.per_eenheid} placeholder={'Per eenheid'} className={'form-field'} onChange={(e) => handleToeslagenChange('tijdstoeslagen', 'per_eenheid', e.target.value, index)} /></div>
                                                    <div className="field long"><input type="text" value={item.opmerking} placeholder={'Eventuele opmerking'} className={'form-field'} onChange={(e) => handleToeslagenChange('tijdstoeslagen', 'opmerking', e.target.value, index)} /></div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="actions">
                                        {
                                            toeslagen.fields.tijdstoeslagen.value.length > 1 ?
                                            <button className="remove" onClick={() => handleToeslagen('remove', 'tijdstoeslagen')}><Icon.DashCircleFill /></button>
                                            :
                                            null
                                        }  
                                        <button className="add" onClick={() => handleToeslagen('add', 'tijdstoeslagen')}><Icon.PlusCircleFill /></button>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-block">
                                <h4>Douanetoeslagen</h4>
                                <div className="toeslagen">
                                    <div className="rows">
                                        {
                                            toeslagen.fields.douanetoeslagen.value.map((item, index) =>  {
                                                return <div key={index} className="toeslag">
                                                    <div className="field long">
                                                    <Select 
                                                        options={douaneToeslagen} 
                                                        className={item.value ? 'select-option valid' : 'select-option'} 
                                                        placeholder={'Maak een keuze'} value={item.soort_toeslag} 
                                                        onChange={(e) => handleToeslagenChange('douanetoeslagen', 'soort_toeslag', e, index)} 
                                                        isClearable
                                                        disabled={isLoading}
                                                    />
                                                    </div>
                                                    <div className="field short"><input type="text" value={item.eenheid} placeholder={'Eenheid'} className={'form-field'} onChange={(e) => handleToeslagenChange('douanetoeslagen', 'eenheid', e.target.value, index)} /></div>
                                                    <div className="field short"><input type="number" value={item.per_eenheid} placeholder={'Per eenheid'} className={'form-field'} onChange={(e) => handleToeslagenChange('douanetoeslagen', 'per_eenheid', e.target.value, index)} /></div>
                                                    <div className="field long"><input type="text" value={item.opmerking} placeholder={'Eventuele opmerking'} className={'form-field'} onChange={(e) => handleToeslagenChange('douanetoeslagen', 'opmerking', e.target.value, index)} /></div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="actions">
                                        {
                                            toeslagen.fields.douanetoeslagen.value.length > 1 ?
                                            <button className="remove" onClick={() => handleToeslagen('remove', 'douanetoeslagen')}><Icon.DashCircleFill /></button>
                                            :
                                            null
                                        }  
                                        <button className="add" onClick={() => handleToeslagen('add', 'douanetoeslagen')}><Icon.PlusCircleFill /></button>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-block">
                                <h4>Overige toeslagen</h4>
                                <div className="toeslagen">
                                    <div className="rows">
                                        {
                                            toeslagen.fields.afstapeltoeslagen.value.map((item, index) =>  {
                                                return <div key={index} className="toeslag">
                                                    <div className="field long">
                                                    <Select 
                                                        options={afstapelToeslagen} 
                                                        className={item.value ? 'select-option valid' : 'select-option'} 
                                                        placeholder={'Maak een keuze'} value={item.soort_toeslag} 
                                                        onChange={(e) => handleToeslagenChange('afstapeltoeslagen', 'soort_toeslag', e, index)} 
                                                        isClearable
                                                        disabled={isLoading}
                                                    />
                                                    </div>
                                                    <div className="field short"><input type="text" value={item.eenheid} placeholder={'Eenheid'} className={'form-field'} onChange={(e) => handleToeslagenChange('afstapeltoeslagen', 'eenheid', e.target.value, index)} /></div>
                                                    <div className="field short"><input type="number" value={item.per_eenheid} placeholder={'Per eenheid'} className={'form-field'} onChange={(e) => handleToeslagenChange('afstapeltoeslagen', 'per_eenheid', e.target.value, index)} /></div>
                                                    <div className="field long"><input type="text" value={item.opmerking} placeholder={'Eventuele opmerking'} className={'form-field'} onChange={(e) => handleToeslagenChange('afstapeltoeslagen', 'opmerking', e.target.value, index)} /></div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className="actions">
                                        {
                                            toeslagen.fields.afstapeltoeslagen.value.length > 1 ?
                                            <button className="remove" onClick={() => handleToeslagen('remove', 'afstapeltoeslagen')}><Icon.DashCircleFill /></button>
                                            :
                                            null
                                        }  
                                        <button className="add" onClick={() => handleToeslagen('add', 'afstapeltoeslagen')}><Icon.PlusCircleFill /></button>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-block">
                                <textarea value={toeslagen.fields.overige_afspraken.value} placeholder={toeslagen.fields.overige_afspraken.label + (toeslagen.fields.overige_afspraken.required ? '*' : '')} className={toeslagen.fields.overige_afspraken.value ? 'form-field valid' : 'form-field'} rows="10" cols="50" onChange={(e) => handleChange(toeslagen, setToeslagen, 'overige_afspraken', e.target.value)}></textarea>
                            </div>
                        </div>
                    </div>


                    {/* Stap 4a */}
                    <div id={dieseltoeslagen.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{dieseltoeslagen.label}</h3>{dieseltoeslagen.icon}</div>
                            <div className="sub-block">
                                <div className="radio-group">
                                    <label>{dieseltoeslagen.fields.dieseltoeslag.label}</label>
                                    <div className="radio-option">
                                        <input type="radio" id="dieseltoeslag_ja" name="dieseltoeslag" value="true" defaultChecked={dieseltoeslagen.fields.dieseltoeslag.value === 'true' ? true : false} onChange={(e) => handleChange(dieseltoeslagen, setDieseltoeslagen, 'dieseltoeslag', e.target.value)} />
                                        <label htmlFor="dieseltoeslag_ja">Ja</label>
                                    </div>
                                    <div className="radio-option">
                                        <input type="radio" id="dieseltoeslag_nee" name="dieseltoeslag" value="false" defaultChecked={dieseltoeslagen.fields.dieseltoeslag.value === 'true' ? false : true} onChange={(e) => {
                                            handleChange(dieseltoeslagen, setDieseltoeslagen, 'dieseltoeslag', e.target.value)
                                            }} />
                                        <label htmlFor="dieseltoeslag_nee">Nee</label>
                                    </div>
                                 
                                </div>
                                <div className="input-field select-field">
                                    <Select 
                                        options={dieselToeslagenOptions} 
                                        className={dieseltoeslagen.fields.soort_dieseltoeslag.value ? 'select-option valid' : 'select-option'} 
                                        placeholder={dieseltoeslagen.fields.soort_dieseltoeslag.label + (dieseltoeslagen.fields.soort_dieseltoeslag.required ? '*' : '')} value={dieseltoeslagen.fields.soort_dieseltoeslag.value} 
                                        onChange={(e) => handleChange(dieseltoeslagen, setDieseltoeslagen, 'soort_dieseltoeslag', e)} 
                                        isClearable
                                        disabled={isLoading}
                                    />
                                    { dieseltoeslagen.fields.soort_dieseltoeslag.value && ( <span className="placeholder">{dieseltoeslagen.fields.soort_dieseltoeslag.label + (dieseltoeslagen.fields.soort_dieseltoeslag.required ? '*' : '')}</span>) }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Stap 5a */}
                    <div id={bijlagen.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{bijlagen.label}</h3>{bijlagen.icon}</div>
                            <div className="sub-block">
                                <div className="checkboxes">
                                    {
                                        bijlagenOptions.map((item, index) =>  {
                                            return <div key={index} className="checkbox">
                                                <input type="checkbox" id={'bijlagen-'+ item.value} value={item.value} onChange={handleBijlagenChange} checked={bijlagen.fields.bijlagen.value.includes(item.value)} />
                                                <label htmlFor={'bijlagen-' + item.value}> {item.label}</label>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Stap 6a */}
                    <div id={zones.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{zones.label}</h3>{zones.icon}</div>
                            { mapImageUrl ? <button onClick={() => setShowMapImage(true)} className="show-map">Bekijk kaart</button> : null }
                            <button onClick={addExtraZoneRow} className="add-zone-row" style={{position: 'absolute', right: (mapImageUrl ? 215 : 75)}}>Extra rij per zone</button>
                            
                            <div className="sub-block">
                                <div className="zones">
                                    {
                                        zones.fields.zones.value.map((item, index) =>  {
                                            return <div key={index} className="zone">
                                                    <div className="name">{item.naam}</div>
                                                    <div className="fields">
                                                        {
                                                            item.postcodes.map((subItem, subIndex) =>  {
                                                                return <input key={subIndex} type="text" value={subItem.postcode} onChange={(e) => handleZones(e.target.value, index, subIndex)} />
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                        })
                                    }
                                    {
                                        zones.fields.zones.value.length > 5 ?
                                        <button onClick={() => addOrRemoveZones('remove')} className="add-remove">Rij verwijderen -</button>
                                        :
                                        null
                                    }
                                    {
                                        zones.fields.zones.value.length < 20 ?
                                        <button onClick={() => addOrRemoveZones('add')} className="add-remove">Rij toevoegen +</button>
                                        :
                                        null
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Stap 7a */}
                    <div id={divisieVariabelen.id} className="block">
                        <div className="content">
                            <div className="block-header"><h3>{divisieVariabelen.label}</h3>{divisieVariabelen.icon}</div>
                            <div className="sub-block">
                                <div className="two-column">
                                    <InputField section={divisieVariabelen} state={setDivisieVariabelen} field={'bedrag_wachtkosten'} handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={divisieVariabelen} state={setDivisieVariabelen} field={'percentage_waddentoeslag'} handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <div className="two-column">
                                    <InputField section={divisieVariabelen} state={setDivisieVariabelen} field={'percentage_doorleveringen'} handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={divisieVariabelen} state={setDivisieVariabelen} field={'percentage_tweede_levering'} handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <div className="two-column">
                                    <InputField section={divisieVariabelen} state={setDivisieVariabelen} field={'percentage_retourneren'} handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={divisieVariabelen} state={setDivisieVariabelen} field={'tijdstip_opgave_orders'} handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <div className="two-column">
                                    <InputField section={divisieVariabelen} state={setDivisieVariabelen} field={'tijdstip_zendingen_beschikbaarheid'} handleChange={handleChange} isLoading={isLoading} />
                                    <InputField section={divisieVariabelen} state={setDivisieVariabelen} field={'percentage_europallets'} handleChange={handleChange} isLoading={isLoading} />
                                </div>
                                <div className="two-column">
                                    <InputField section={divisieVariabelen} state={setDivisieVariabelen} field={'aantal_dagen_reclames'} handleChange={handleChange} isLoading={isLoading} />
                                </div>
                            </div>
                        </div>
                    </div>

                   


                </div>

                <div className="checklist">
                    <div className="checklist-block">
                        <div className="block-header"><h3>Overzicht</h3><Icon.ListCheck /></div>
                        <div className="list">
                            <h4>Stap 1</h4>
                            <a href={'#' + klantGegevens.id} className="list-item"><Icon.CheckCircleFill color={klantGegevens.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (klantGegevens.valid ? '#00D100' : '#001C43')}}>{klantGegevens.label}</span></a>
                            <a href={'#' + transportGegevens.id} className="list-item"><Icon.CheckCircleFill color={transportGegevens.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (transportGegevens.valid ? '#00D100' : '#001C43')}}>{transportGegevens.label}</span></a>
                            <a href={'#' + offerteGegevens.id} className="list-item"><Icon.CheckCircleFill color={offerteGegevens.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (offerteGegevens.valid ? '#00D100' : '#001C43')}}>{offerteGegevens.label}</span></a>
                            <a href={'#' + facturatie.id} className="list-item"><Icon.CheckCircleFill color={facturatie.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (facturatie.valid ? '#00D100' : '#001C43')}}>{facturatie.label}</span></a>
                        </div>
                        <div className="list">
                            <h4>Stap 2</h4>
                            <a href={'#' + begeleidendSchrijven.id} className="list-item"><Icon.CheckCircleFill color={begeleidendSchrijven.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (begeleidendSchrijven.valid ? '#00D100' : '#001C43')}}>{begeleidendSchrijven.label}</span></a>
                            {
                                transportGegevens.fields.ftl_ltl.value.value !== 'ftl' ?
                                <a href={'#' + lading.id} className="list-item"><Icon.CheckCircleFill color={lading.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (lading.valid ? '#00D100' : '#001C43')}}>{lading.label}</span></a>
                                :
                                null
                            }
                            <a href={'#' + land.id} className="list-item"><Icon.CheckCircleFill color={land.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (land.valid ? '#00D100' : '#001C43')}}>{land.label}</span></a>
                        </div>
                        <div className="list">
                            <h4>Stap 3</h4>
                            <a href={'#' + toeslagen.id} className="list-item"><Icon.CheckCircleFill color={toeslagen.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (toeslagen.valid ? '#00D100' : '#001C43')}}>{toeslagen.label}</span></a>
                        </div>
                        <div className="list">
                            <h4>Stap 4</h4>
                            <a href={'#' + dieseltoeslagen.id} className="list-item"><Icon.CheckCircleFill color={dieseltoeslagen.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (dieseltoeslagen.valid ? '#00D100' : '#001C43')}}>{dieseltoeslagen.label}</span></a>
                        </div>
                        <div className="list">
                            <h4>Stap 5</h4>
                            <a href={'#' + bijlagen.id} className="list-item"><Icon.CheckCircleFill color={bijlagen.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (bijlagen.valid ? '#00D100' : '#001C43')}}>{bijlagen.label}</span></a>
                        </div>
                        <div className="list">
                            <h4>Stap 6</h4>
                            <a href={'#' + zones.id} className="list-item"><Icon.CheckCircleFill color={zones.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (zones.valid ? '#00D100' : '#001C43')}}>{zones.label}</span></a>
                        </div>
                        <div className="list">
                            <h4>Stap 7</h4>
                            <a href={'#' + divisieVariabelen.id} className="list-item"><Icon.CheckCircleFill color={divisieVariabelen.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (divisieVariabelen.valid ? '#00D100' : '#001C43')}}>{divisieVariabelen.label}</span></a>
                        </div>

                        {
                            klantGegevens.valid &&
                            transportGegevens.valid &&
                            offerteGegevens.valid &&
                            facturatie.valid &&
                            begeleidendSchrijven.valid &&
                            lading.valid &&
                            land.valid &&
                            toeslagen.valid &&
                            dieseltoeslagen.valid &&
                            bijlagen.valid ?
                            <button onClick={checkChanges}>Offerte bijwerken</button>
                            :
                            null
                        }

                    </div>
                </div>  

            </div>
            }

            <div className={'view-map side-preview ' + (showMapImage ? 'show' : '')}>
                <div className="side-preview-header">
                    <Icon.XCircleFill onClick={() => setShowMapImage(false)} />
                </div>
                <div className="side-preview-content">
                    {
                        mapImageUrl ?
                        <img src={mapImageUrl} />
                        :
                        null
                    }
                </div>
            </div>

            <div className={'loader-offerte ' + (savingOfferte ? 'show' : '')}>
                <div className="loader-content">
                    <Lottie lottieRef={lottieAnimation} autoplay={true} animationData={creatingOfferteAnimation} />
                    <p>Offerte bijwerken ...</p>
                </div>
            </div>
            
            <Toaster />
        </div>
        <BottomNav page="/offerte-bewerken" />
        {/* <img src={vsdvArtwork} className="artwork" /> */}
    </div>
    );
};
export default OfferteBewerken;