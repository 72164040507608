import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//Helper functions
import { getSettings, addManualLog, notifyError, notifySuccess, downloadExcelLTL, downloadExcelFTL, handleDownload, getFileContents } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import DataTable from 'react-data-table-component';
import { saveAs } from "file-saver";
import Rodal from 'rodal';
import { TailSpin } from 'react-loader-spinner';
import PulseDot from 'react-pulse-dot';
import { Tooltip } from 'react-tooltip';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import Lottie from "lottie-react";
import Select from 'react-select';
import exportFromJSON from 'export-from-json';
import { exportToExcel } from 'react-json-to-excel';
import { confirmAlert } from 'react-confirm-alert';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import OfferteDocument from '../../../components/offerte/offerte-document';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import creatingOfferteAnimation from "../../../assets/animations/create-dossier.json";

//styles
import './offertes.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';


const Datum = (props) => <div><span className="sort sort-date">{props.sortDate}</span><p>{props.datum}</p></div>
const Actions = (props) => {
    const signedIndex = props.offerte.versies.findIndex(obj => obj.getekend === true);
    const lastSignedIndex = props.offerte.versies.reduce((acc, current, index) => {
        if (current.getekend) {
            return index;
        } else {
            return acc;
        }
    }, -1);
    const fileUrl = props.offerte.versies[lastSignedIndex].bestand ? props.environment + '' + props.offerte.versies[lastSignedIndex].bestand.url : '';
    const fileName = props.offerte.versies[lastSignedIndex].bestand ? props.offerte.versies[lastSignedIndex].bestand.name : '';
    const fileNameFs = props.offerte.versies[lastSignedIndex].pad;
    return (
        <div className="actions">
            {/* {
                fileName ?
                <>
                    <span className="action-icon download" id={'download-' + props.id} onClick={() => { saveAs(fileUrl, fileName) }}><Icon.Download /></span>
                    <Tooltip
                        anchorId={'download-' + props.id}
                        place="top"
                        content="Download getekende offerte"
                    />
                </>
                :
                null
            } */}
            
            {
                fileNameFs ?
                <>
                    <span className="action-icon download" id={'downloadd-' + props.id} onClick={() => { props.handleDownload(props.offerte.offertenummer, 'Getekend', fileNameFs) }}><Icon.Download /></span>
                    <Tooltip
                        anchorId={'downloadd-' + props.id}
                        place="top"
                        content="Download getekende offerte"
                    />
                </>
                :
                null
            }
            {
                props.offerte.ltl || props.offerte.twee_ltl ?
                <>
                    <span className="action-icon" id={'excel-' + props.id} onClick={() => { props.downloadExcelLTL(props.offerte, false) }}><Icon.FileEarmarkSpreadsheet /></span>
                    <Tooltip
                        anchorId={'excel-' + props.id}
                        place="top"
                        content="Download prijslijst LTL"
                    />
                </>
                :
                null
            }
            {
                props.offerte.twee_ltl ?
                <>
                    <span className="action-icon" id={'excel-twee-ltl-' + props.id} onClick={() => { props.downloadExcelLTL(props.offerte, true) }}><Icon.FileEarmarkSpreadsheet /></span>
                    <Tooltip
                        anchorId={'excel-twee-ltl-' + props.id}
                        place="top"
                        content="Download prijslijst 2 LTL"
                    />
                </>
                :
                null
            }
            {
                props.offerte.ftl ?
                <>
                    <span className="action-icon" id={'excel-ftl-' + props.id} onClick={() => { props.downloadExcelFTL(props.offerte) }}><Icon.FileEarmarkSpreadsheet /></span>
                    <Tooltip
                        anchorId={'excel-ftl-' + props.id}
                        place="top"
                        content="Download prijslijst FTL"
                    />
                </>
                :
                null
            }
            {
                props.medewerker.gebruiker.role.type !== 'administratie' ?
                <>
                    <span className="action-icon revenue" id={'revenue-' + props.id} onClick={() => { props.setOfferteToChange(props.offerte); props.setRevenue(props.offerte.omzetschatting); props.setShowModalRevenue(true); }}><Icon.Tag /></span>
                    <Tooltip
                        anchorId={'revenue-' + props.id}
                        place="top"
                        content="Wijzig omzetschatting"
                    />
                </>
                :
                null
            }
            <>
                <span className="action-icon logs" id={'logs-' + props.id} onClick={() => { 
                    props.setOfferte(props.offerte); 
                    props.setShowStats(true); 
                    props.setWorkflow(props.offerte.workflow); 
                    props.setStats(props.offerte.statistieken); 
                    props.setLogs(props.offerte.logs);
                    props.setOfferteVersies(props.offerte.versies);
                    props.setSignedVersieIndex(lastSignedIndex); }}><Icon.BarChart /></span>
                <Tooltip
                    anchorId={'logs-' + props.id}
                    place="top"
                    content="Toon statistieken"
                />
            </>
            {
                props.medewerker.gebruiker.role.type !== 'administratie' ?
                <>
                    <span className="action-icon" id={'duplicate-' + props.id} onClick={() => { props.setOfferteToDuplicate(props.offerte.id); props.setKlantDataToDuplicate(props.offerte.klant); props.setKlantToDuplicate({value: props.offerte.klant.id, label: props.offerte.klant.bedrijfsnaam}); props.setShowModal(true); }}><Icon.Back /></span>
                    <Tooltip
                        anchorId={'duplicate-' + props.id}
                        place="top"
                        content="Dupliceren"
                    />
                </>
                :
                null
            }
            {
                props.medewerker.gebruiker.role.type !== 'administratie' ?
                <>
                    <span className="action-icon" id={'change-' + props.id} onClick={() => { props.setOfferteToChange(props.offerte); props.setShowModalStatus(true); }}><Icon.Gear /></span>
                    <Tooltip
                        anchorId={'change-' + props.id}
                        place="top"
                        content="Status handmatig wijzigen"
                    />
                </>
                :
                null
            }
            {
                props.medewerker.gebruiker.role.type !== 'administratie' ?
                <>
                    <span className="action-icon delete" id={'delete-' + props.id} onClick={() => { props.confirmDelete(props.id) }}><Icon.Trash3 /></span>
                    <Tooltip
                        anchorId={'delete-' + props.id}
                        place="top"
                        content="Verwijderen"
                    />
                </>
                :
                null
            }
        </div>
    )
};

const AfgerondeOffertes = () => {

    //Set variables
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchValue = searchParams.get('search');
    const loggedIn = localStorage.getItem('ingelogd');
    const [medewerker, setMedewerker] = useState(JSON.parse(localStorage.getItem('medewerker')));
    const environment = process.env.REACT_APP_APP_URL;
    const [loadingOffertes, setLoadingOffertes] = useState(true);
    const [loaderMessage, setLoaderMessage]  = useState('');
    const [offertes, setOffertes] = useState([]);
    const [klantData, setKlantData] = useState([]);
    const [klanten, setKlanten] = useState([]);
    const [klantToDuplicate, setKlantToDuplicate] = useState({value: '', label: ''});
    const [klantDataToDuplicate, setKlantDataToDuplicate] = useState([]);
    const [offerte, setOfferte] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [showStats, setShowStats] = useState(false);
    const [workflow, setWorkflow] = useState([]);
    const [stats, setStats] = useState([]);
    const [logs, setLogs] = useState([]);
    const [manualLog, setManualLog] = useState('');
    const [addingLog, setAddingLog] = useState(false);
    const [offerteVersies, setOfferteVersies] = useState([]);
    const [signedVersieIndex, setSignedVersieIndex] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [offerteToDuplicate, setOfferteToDuplicate] = useState('');
    const [percentageType, setPercentageType] = useState('up');
    const [percentageValues, setPercentageValues] = useState('');
    const [geldigVan, setGeldigVan] = useState('');
    const [geldigTot, setGeldigTot] = useState('');
    const [savingOfferte, setSavingOfferte] = useState(false);
    const [offerteToChange, setOfferteToChange] = useState('');
    const [showModalStatus, setShowModalStatus] = useState(false);
    const [status, setStatus] = useState('afgerond');
    const [sendMail, setSendMail] = useState(false);
    const [revenue, setRevenue] = useState('');
    const [showModalRevenue, setShowModalRevenue] = useState(false);
    const lottieAnimation = useRef();

    const delay = ms => new Promise(res => setTimeout(res, ms));

    //Function to sort on string value
    const filteredData = offertes.filter((item) => {
        const columnsToSearch = ['offerte', 'klantnaam', 'divisie', 'type', 'vestiging', 'datum', 'auteur']; // Specify the columns you want to include in the search
        for (let i = 0; i < columnsToSearch.length; i++) {
          const column = columnsToSearch[i];
          if (column === 'datum') {
            var cellData = item[column].props.datum.toLowerCase();
          } else {
            var cellData = item[column].toString().toLowerCase();
          }
          if (cellData.includes(filterText.toLowerCase())) {
            return true; // Return true if any column matches the search text
          }
        }
        return false; // Return false if no columns match the search text
    });

    //Function to do custom sort on timestamp instead of datestring
    const customDateSort = (rowA, rowB) => {
        const a = rowA.datum.props.sortDate;
        const timeStampA = new Date(a).getTime();
        const b = rowB.datum.props.sortDate;
        const timeStampB = new Date(b).getTime();
        if (timeStampA > timeStampB) {
            return 1;
        }
        if (timeStampB > timeStampA) {
            return -1;
        }
        return 0;
    };

    const handleRowSelected = (state) => {
        setSelectedRows(state.selectedRows);
    };

    //Columns for datatable
    const columns = [
        {
            name: 'Offerte',
            selector: row => row.offerte,
            sortable: true
        },
        {
            name: 'Klantnaam',
            selector: row => row.klantnaam,
            sortable: true
        },
        {
            name: 'Divisie',
            selector: row => row.divisie,
            sortable: true
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true
        },
        {
            name: 'Vestiging',
            selector: row => row.vestiging,
            sortable: true
        },
        {
            name: 'Datum',
            selector: row => row.datum,
            sortable: true
        },
        {
            name: 'Auteur',
            selector: row => row.auteur,
            sortable: true
        },
        {
            name: 'Acties',
            selector: row => row.acties,
            minWidth: "280px"
        },
    ];
    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles'
    };
    
    const downloadCsv = (data, type, offertenummer) => {
        var fileName = 'export-prijslijst-' + type + '-' + offertenummer;
        exportToExcel(data, fileName);
        //const exportType =  exportFromJSON.types.xls
        //exportFromJSON({ data, fileName, exportType })
    }

    const getOffertes = async () => {
        try {
            const response = await api.get('/offertes?status=afgerond');
            if (response.data) {
                const arrayData = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        id: item.id,
                        offerte: item.offertenummer,
                        klantnaam: item.klant.bedrijfsnaam,
                        divisie: item.divisie.label,
                        type: item.soort_offerte ? item.soort_offerte.naam : '',
                        vestiging: item.vestiging ? item.vestiging.naam : '',
                        datum: <Datum 
                            sortDate={item.datum}
                            datum={moment(item.datum).format('DD-MM-YYYY')}
                        />,
                        auteur: item.aangemaakt_door.voornaam + ' ' + item.aangemaakt_door.achternaam,
                        acties: <Actions 
                                    id={item.id} 
                                    environment={environment}
                                    medewerker={medewerker} 
                                    offerte={item} 
                                    setOfferte={setOfferte} 
                                    setWorkflow={setWorkflow}
                                    setLogs={setLogs}
                                    confirmDelete={confirmDelete}
                                    setOfferteVersies={setOfferteVersies}
                                    setSignedVersieIndex={setSignedVersieIndex} 
                                    setOfferteToDuplicate={setOfferteToDuplicate}
                                    setOfferteToChange={setOfferteToChange}
                                    setKlantToDuplicate={setKlantToDuplicate}
                                    setKlantDataToDuplicate={setKlantDataToDuplicate} 
                                    setShowModal={setShowModal}
                                    setShowModalStatus={setShowModalStatus} 
                                    setStats={setStats} 
                                    setShowStats={setShowStats} 
                                    downloadExcelLTL={downloadExcelLTL}
                                    downloadExcelFTL={downloadExcelFTL}
                                    handleDownload={handleDownload}
                                    setRevenue={setRevenue}
                                    setShowModalRevenue={setShowModalRevenue} 
                                />,
                    };
                    arrayData.push(obj);
                })
                setOffertes(arrayData);
                setLoadingOffertes(false);
                if (searchValue) {
                    setFilterText(searchValue);
                }
            }
        } catch (error) {}
    }

    //Get all klanten on load
    const getKlanten = async () => {
        try {
            const response = await api.get('/klanten');
            if (response.data) {
                setKlantData(response.data);
                const arrOptions = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        value: item.id,
                        label: item.bedrijfsnaam
                    };
                   arrOptions.push(obj); 
                })
                setKlanten(arrOptions);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const duplicateOfferte = async (event) => {
        event.preventDefault();
        setShowModal(false);
        setSavingOfferte(true);
        const obj = {
            klant: klantDataToDuplicate,
            medewerker: medewerker.voornaam + ' ' + medewerker.achternaam,
            type: percentageType,
            percentage: Number(percentageValues),
            geldig_van: geldigVan,
            geldig_tot: geldigTot
        }
        try {
            const response = await api.post('/offertes/duplicate/' + offerteToDuplicate, {
                data: obj
            });
            if (response.data) {
                await delay(1500);
                navigate('/openstaand');
            }
        } catch (error) {
            setSavingOfferte(false);
            setShowModal(true);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const duplicateOffertes = async (event) => {
        event.preventDefault();
        setLoaderMessage('Offertes dupliceren en aanmaken... Dit kan even duren');
        setShowModal(false);
        setSavingOfferte(true);
        const arrayIds = [];
        selectedRows.map((item, index) =>  {
            arrayIds.push(item.id);
        })
        const obj = {
            ids: arrayIds,
            medewerker: medewerker.voornaam + ' ' + medewerker.achternaam,
            type: percentageType,
            percentage: Number(percentageValues),
            geldig_van: geldigVan,
            geldig_tot: geldigTot
        }
        try {
            const response = await api.post('/offertes/duplicate-offertes', {
                data: obj
            });
            if (response.data) {
                await delay(1500);
                navigate('/openstaand');
            }
        } catch (error) {
            setSavingOfferte(false);
            setShowModal(true);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const archiveOffertes = async (rows) => {
        setLoaderMessage('Offertes archiveren...');
        setSavingOfferte(true);
        const idsToArchive = rows.map(obj => obj.id);
        try {
            const response = await api.post('/offertes/archive', {
                data: {
                    idsToArchive: idsToArchive
                }
            });
            if (response.data) {
                await delay(1000);
                notifySuccess('Offertes zijn gearchiveerd');
                getOffertes();
                setSavingOfferte(false);
            }
        } catch (error) {
            setSavingOfferte(false);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const confirmBulkArchive = (rows) => {
        confirmAlert({
            title: 'Alle geselecteerde offertes worden gearchiveerd',
            message: 'Wil je doorgaan?',
            buttons: [
                {
                label: 'Ja',
                onClick: () => archiveOffertes(rows)
                },
                {
                label: 'Nee'
                }
            ]
        });
    }

    const deleteOfferte = async (id) => {
        try {
            const response = await api.delete('/offertes/' + id);
            if (response.data) {
                notifySuccess('Offerte is succesvol verwijderd');
                getOffertes();
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const confirmDelete = (id) => {
        confirmAlert({
            title: 'De geselecteerde offerte wordt compleet verwijderd',
            message: 'Wil je doorgaan?',
            buttons: [
                {
                label: 'Ja',
                onClick: () => deleteOfferte(id)
                },
                {
                label: 'Nee'
                }
            ]
        });
    }

    const handleAddManualLog = async (event, value, offerteId) => {
        setAddingLog(true);
        const logs = await addManualLog(event, value, offerteId);
        setLogs(logs);
        setAddingLog(false);
        notifySuccess('Log succesvol toegevoegd');
    };

    const changeRevenue = async (event) => {
        event.preventDefault();
        setLoaderMessage('Offerte omzetschatting wijzigen...');
        setShowModalRevenue(false);
        setSavingOfferte(true);
        try {
            const response = await api.post('/offertes/change-omzetschatting/' + offerteToChange.id, {
                data: {
                    omzetschatting: revenue
                }
            });
            if (response.data) {
                await delay(2500);
                getOffertes();
                notifySuccess('Offerte omzetschatting is gewijzigd');
                setSavingOfferte(false);
            }
        } catch (error) {
            setShowModalRevenue(true);
        }
    }

    const changeStatusOfferte = async (event) => {
        event.preventDefault();
        setLoaderMessage('Offerte status wijzigen...');
        setShowModalStatus(false);
        setSavingOfferte(true);
        var versies = offerteToChange.versies;
        const log = {
            "omschrijving": 'Offerte status handmatig gewijzigd in ' + status + ' door ' + medewerker.voornaam + ' ' + medewerker.achternaam,
            "datum_tijd": new Date().toISOString()
        };
        offerteToChange.logs.unshift(log);
        try {
            const response = await api.post('/offertes/status/' + offerteToChange.id, {
                data: {
                    status: status,
                    versies: versies,
                    logs: offerteToChange.logs,
                    send_mail: sendMail
                }
            });
            if (response.data) {
                await delay(2500);
                getOffertes();
                notifySuccess('Offerte status is gewijzigd');
                setSavingOfferte(false);
            }
        } catch (error) {
            setSavingOfferte(false);
            setShowModal(true);
            console.log(error);
        }
    }

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        return formattedTime;
    }

    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getOffertes();
        getKlanten();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/afgerond" />
        <div className="dashboard-content">
            <DashboardHeader title="Afgeronde offertes" />

            {
                loadingOffertes ?
                <>
                <Skeleton height={40} style={{marginBottom:10, marginTop:20}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                </>
                :
                <>
                <div className="table-header">
                    <div className="actions">
                    {
                        selectedRows.length > 1 ?
                        <div className="buttons">
                            <button className="red" onClick={() => setShowModal(true)}>Offertes dupliceren</button>
                            <button className="red" onClick={() => confirmBulkArchive(selectedRows)}>Offertes archiveren</button>
                        </div>
                        :
                        <span></span>
                    }
                    <input
                        type="text"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        placeholder="Zoeken..."
                    />
                    </div>
                </div>
                {
                    offertes.length > 0 ?
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationPerPage={100}
                        fixedHeader
                        noDataComponent={<p>Niks gevonden...</p>}
                        selectableRows
                        onSelectedRowsChange={handleRowSelected}
                    />
                    :
                    <p>Er zijn geen offertes gevonden.</p>
                }
                </>
            }

            <div className={'view-stats side-preview ' + (showStats ? 'show' : '')}>
                <div className="side-preview-header">
                    <h3>Statistieken</h3>
                    <Icon.XCircleFill onClick={() => setShowStats(false)} />
                </div>
                <div className="side-preview-content">
                    {
                        workflow.length > 0 ?
                        <ul id="workflow">
                            {
                                workflow.map((item, index) =>  {
                                    if (item.voltooid) {
                                        var itemClass = 'done';
                                        var showPulse = false;
                                    } else {
                                        if (index === 0 && offerte.status === 'debiteuren') {
                                            var itemClass = 'pending';
                                            var showPulse = true;
                                        }
                                        else if (index !== 0 && workflow[index - 1].voltooid) {
                                            var itemClass = 'pending';
                                            var showPulse = true;
                                        } else {
                                            var itemClass = 'disabled';
                                            var showPulse = false;
                                        }
                                    }
                                    return  <li key={index} className={itemClass}>
                                        <div className="icon">
                                            {index === 0 ? <Icon.FileSpreadsheet /> : null}
                                            {index === 1 ? <Icon.CurrencyEuro /> : null}
                                            {index === 2 ? <Icon.Receipt /> : null}
                                            {index === 3 ? <Icon.Gift /> : null}
                                            {index === 4 ? <Icon.CheckAll /> : null}
                                        </div>
                                        {showPulse ? <PulseDot color='#cb0a32' style={{fontSize: 36}} className="pulse"/> : null}
                                        <p>{item.naam}</p>
                                        <span className="info">{item.info}</span>
                                        {
                                            item.log ?
                                            <span className="log">{item.log}</span>
                                            :
                                            null
                                        }
                                        <div className="line"></div>
                                    </li>
                                })
                            }
                        </ul>
                        :
                        null
                    }

                    {
                        logs.length > 0 ?
                        <table id="logs">
                            <thead>
                                <tr>
                                    <th>Omschrijving</th>
                                    <th>Datum en tijd</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    logs.map((item, index) =>  {
                                        return  <tr key={index}>
                                                    <td>{item.omschrijving}</td>
                                                    <td>{moment(item.datum_tijd).format('DD-MM-YYYY HH:mm')}</td>
                                                </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        :
                        <p>Er zijn (nog) geen statistieken beschikbaar</p>
                    }
                    {
                        stats.length > 0 ?
                        <table id="stats">
                            <thead>
                                <tr>
                                    <th>Datum</th>
                                    <th>Tijdstip</th>
                                    <th>Tijd bekeken</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stats.map((item, index) =>  {
                                        return  <tr key={index}>
                                                    <td>{moment(item.datum).format('DD-MM-YYYY')}</td>
                                                    <td>{item.tijdstip}</td>
                                                    <td>{formatTime(item.tijd_bekeken)}</td>
                                                </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        :
                        <p>Er zijn nog geen statistieken beschikbaar</p>
                    }
                    <form id="add-log" onSubmit={(e) => handleAddManualLog(e, manualLog, offerte.id)}>
                        <input type="text" value={manualLog} className="field" onChange={(e) => setManualLog(e.target.value)} placeholder="Voer hier eigen logomschrijving in..." required />
                        <div className="add-log-actions">
                            {
                                addingLog ?
                                <TailSpin
                                    height="25"
                                    width="25"
                                    color="#CB0A32"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                :
                                null
                            }
                            <input type="submit" className="button" value="Toevoegen" disabled={addingLog} />
                        </div>
                    </form>
                    <div className="versions">
                        <p>Offerte versies</p>
                        {
                            offerteVersies.length > 0 ?
                            <ul className="version-list">
                            {
                                offerteVersies.map((item, index) =>  {
                                    return <>
                                    {/* {
                                        item.bestand ?
                                        <li key={index} onClick={() => { saveAs(environment + '' + item.bestand.url, item.bestand.name) }}><Icon.FilePdf />{'Versie ' + item.versie}{item.getekend ? <span>(getekend)</span> : <span></span>}</li>
                                        :
                                        null
                                    } */}
                                    {
                                        item.pad ?
                                        <li key={index} onClick={() => { index === signedVersieIndex ? handleDownload(offerte.offertenummer, 'Getekend', item.pad) : handleDownload(offerte.offertenummer, 'leeg', item.pad) }}><Icon.FilePdf />{'Versie ' + item.versie}{item.getekend ? <span>(getekend)</span> : <span></span>}</li>
                                        :
                                        null
                                    }
                                    </>
                                })
                            }
                            </ul>
                            :
                            <p>Er zijn geen versies gevonden...</p>
                        }

                    </div>
                    {
                        offerte.bijlagen ?
                        <div className="versions">
                            <p>Bijlagen</p>
                            {
                                offerte.bijlagen.length > 0 ?
                                <ul className="version-list">
                                {
                                    offerte.bijlagen.map((item, index) =>  {
                                        return <li key={index} onClick={() => { saveAs(environment + '' + item.bestand.url, item.bestand.name) }}><Icon.FilePdf />{item.naam}</li>
                                    })
                                }
                                {
                                    offerte.soort_dieseltoeslag ?
                                    <li onClick={() => { saveAs(environment + '' + offerte.soort_dieseltoeslag.pdf.url, offerte.soort_dieseltoeslag.pdf.name) }}><Icon.FilePdf />{offerte.soort_dieseltoeslag.label}</li>
                                    :
                                    null
                                }
                                </ul>
                                :
                                <p>Er zijn geen bijlagen gevonden...</p>
                            }
                        </div>
                        :
                        null
                    }
                </div>
            </div>

            <Rodal visible={showModal} onClose={() => { setShowModal(false); setOfferteToDuplicate(''); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3 style={{textAlign:'center'}}>{offerteToDuplicate ? 'Offerte dupliceren' : 'Offertes dupliceren'}</h3>
                <form id="duplicate-offerte" className="popup-form" onSubmit={selectedRows.length > 1 && offerteToDuplicate === '' ? duplicateOffertes : duplicateOfferte}>
                    {
                        offerteToDuplicate ?
                        <Select 
                            options={klanten} 
                            className={'select-option'} 
                            placeholder={'Kies een klant'} 
                            onChange={(e) => {setKlantToDuplicate(e); setKlantDataToDuplicate(klantData[klantData.findIndex(item => item.id === e.value)]);}}
                            value={klantToDuplicate}
                            isClearable
                            required
                        />
                        :
                        null
                    }
                    <div className="two-column">
                        <select name="percentage-type" defaultValue={'up'} onChange={(e) => setPercentageType(e.target.value)} id="percentage-type" className="form-field" required>
                            <option value="up">Tarieven ophogen</option>
                            <option value="down">Tarieven verlagen</option>
                        </select>
                        <input type="number" value={percentageValues} onChange={(e) => setPercentageValues(e.target.value)} placeholder={percentageType === 'up' ? 'Tarieven ophogen met %' : 'Tarieven verlagen met %'}  className="form-field" required />
                    </div>
                    <div className="two-column">
                        <input type="date" value={geldigVan} onChange={(e) => setGeldigVan(e.target.value)} placeholder="Geldig van" className="form-field" required />
                        <input type="date" value={geldigTot} onChange={(e) => setGeldigTot(e.target.value)} placeholder="Geldig tot" className="form-field" required />
                    </div>
                    <input type="submit" value={offerteToDuplicate ? 'Offerte dupliceren' : 'Offertes dupliceren'} className="green" />
                </form>
            </Rodal>

            <Rodal visible={showModalRevenue} onClose={() => { setShowModalRevenue(false); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3 style={{textAlign:'center'}}>Omzetschatting wijzigen</h3>
                <form id="change-revenue" className="popup-form" onSubmit={changeRevenue}>
                    <input type="number" value={revenue} onChange={(e) => setRevenue(e.target.value)} placeholder="Omzetschatting" className="form-field" required />
                    <input type="submit" value="Opslaan" className="green" />
                </form>
            </Rodal>

            <Rodal visible={showModalStatus} onClose={() => { setShowModalStatus(false); }} width={500} height={170} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3 style={{textAlign:'center'}}>Status handmatig wijzigen</h3>
                    <form id="change-status" className="popup-form" onSubmit={changeStatusOfferte}>
                        <div className="two-column">
                            <select name="status" onChange={(e) => setStatus(e.target.value)} id="status" className="form-field" required>
                                <option value="" disabled selected>Selecteer status</option>
                                <option value="gearchiveerd">Gearchiveerd</option>
                            </select>
                        </div>
                        <input type="submit" value="Status wijzigen" className="green" />
                    </form>
            </Rodal>
            
            <div className={'loader-offerte ' + (savingOfferte ? 'show' : '')}>
                <div className="loader-content">
                    <Lottie lottieRef={lottieAnimation} autoplay={true} animationData={creatingOfferteAnimation} />
                    <p>{loaderMessage}</p>
                </div>
            </div>

            <Toaster />
        </div>
        <BottomNav page="/afgerond" />
        {/* <img src={vsdvArtwork} className="artwork" /> */}
    </div>
    );
};
export default AfgerondeOffertes;